import React from "react";
import styled from "@emotion/styled";
import { Button, TextLink } from "@vds/buttons";
import { Input, RadioBox, RadioBoxGroup } from "@vds/inputs";
import { Line } from "@vds/lines";
import { DropdownSelect } from "@vds/selects";
import { Body } from "@vds/typography";
import { MediumText, XLargeText } from "../Utility";

export const Header = (props) => {
  return (
    <>
      <XLargeText css={{ padding: "10px 0px" }} bold {...props} />
      <Line type="light"></Line>
    </>
  );
};

export const SLInput = (props) => {
  return (
    <Input
      css={{ padding: "20px 0px" }}
      {...props}
      label={
        <Body size="large" primitive="span" bold>
          {props?.label ?? ""}
        </Body>
      }
    />
  );
};

export const SLRadioBoxGroup = (props) => {
  return (
    <div>
      <MediumText bold>{props?.label ?? ""}</MediumText>
      <RadioBoxGroup {...props} />
    </div>
  );
};

export const SLButton = styled(Button)({
  margin: "10px 20px 10px 0px",
});

export const SLDropDown = (props) => {
  return (
    <div>
      <DropdownSelect
        css={{ margin: "20px 0px" }}
        {...props}
        label={
          <Body size="large" primitive="span" bold>
            {props?.label ?? ""}
          </Body>
        }
      />
    </div>
  );
};

export const SLRadioBox = styled(RadioBox)`
  height: inherit !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
`;
export const SLCheckBox = styled(Input)``;

export const StyledTextLink = styled(TextLink)`
  :hover {
    color: rgb(0, 0, 0);
    border-bottom: 0.125rem solid rgb(0, 0, 0);
  }
`;
