import styled from "styled-components";
import { DropdownSelect } from "@vds/selects";
import { DatePicker } from "@vds/inputs";
import { Button } from "@vds/buttons";
import { Line } from "@vds/lines";

export const SPWrapper = styled.div`
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: -1px;
  margin-left: -13px;
  margin-right: -13px;
  padding-bottom: 50px;
`;

export const FlexRow = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const FlexCol = styled.div`
  margin-left: 8%;
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  width: 60%;
`;

export const ToLabel = styled.div`
  width: 47%;
  margin-left: -35px;
`;

export const CustomDropdownSelect = styled(DropdownSelect)`
  /* .gUuvib {
    border-radius: 20px;
    width: 150%;
  } */
  /* width: 160%; */
`;

export const CustomDatePicker = styled(DatePicker)`
  margin-left: -5px;
`;

export const CustomButton = styled(Button)`
  width: 145px;
  height: 42px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 5%;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 700;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 15px;
  width: 200px;
  height: 118px;
  background-color: #f6f6f6;
  border-radius: 15px;
`;

export const ListLabel = styled.div`
  margin-left: 20px;
  margin-top: 15px;
  color: black;
`;

export const ListCount = styled.div`
  margin-left: 20px;
  margin-top: 12px;
  font-size: 32px;
  font-weight: 700;
  color: black;
`;

export const Heading = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-top: 2.8rem;
  margin-left: 17px;
`;

export const SubHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 1rem;
`;

export const CustomLine = styled(Line)`
  width: 97.5%;
  margin-left: 17px;
  margin-top: 10px;
  background-color: black;
`;

export const ButtonRow = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const ButtonCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  gap: 3rem;
`;

export const CountHeading = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-top: 2.8rem;
  margin-left: 45px;
  letter-spacing: 0.1rem;
  background-color: lightgoldenrodyellow;
`;

export const Wrapper = styled.div`
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  align-self: ${(props) => props.alignSelf};
  background: ${(props) => props.background};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  border-style: ${(props) => props.borderStyle};
  border-top: ${(props) => props.borderTop};
  border-width: ${(props) => props.borderWidth};
  border-color: ${(props) => props.borderColor};
  bottom: ${(props) => props.bottom};
  box-shadow: ${(props) => props.borderShadow};
  color: ${(props) => props.color};
  cursor: ${(props) => (props.cursor ? "pointer" : "")};
  display: ${(props) => props.display};
  flex: ${(props) => props.flex};
  flex-basis: ${(props) => props.flexBasis};
  flex-direction: ${(props) => props.flexDirection};
  flex-flow: ${(props) => props.flexFlow};
  flex-grow: ${(props) => props.flexGrow};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "wrap")};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : props.bold ? "bold" : "")};
  gap: ${(props) => props.gap};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  height: ${(props) => props.height};
  justify-content: ${(props) => props.justifyContent};
  left: ${(props) => props.left};
  letter-spacing: ${(props) => props.letterSpacing};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  max-width: ${(props) => props.maxWidth};
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  padding-bottom: ${(props) => props.paddingBottom};
  position: ${(props) => props.position};
  right: ${(props) => props.right};
  text-align: ${(props) => props.textAlign};
  overflow-wrap: ${(props) => props.overFlowWrap};
  top: ${(props) => props.top};
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};

  & label {
    & p {
      & span {
        display: ${(props) => props.hideOptional};
      }
    }
  }

  &:focus {
    outline: none;
  }
`;
