const baseVtuServiceUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VTU}`;
const baseCameraServiceUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAMERA}`;

export const SEARCH_VTU_DETAILS = baseVtuServiceUrl + "/getVtu";

export const SEARCH_OEM_DETAILS = baseVtuServiceUrl + "/getOem";

export const GET_AVAILABLE_CAMERAS = baseCameraServiceUrl + "/getAvailableCamera";

export const GET_ASSIGNED_CAMERAS = baseCameraServiceUrl + "/getAssignedCamera/";

export const ASSIGN_CAMERAS = baseCameraServiceUrl + "/assignandconfirm";

export const UNASSIGN_CAMERA = baseCameraServiceUrl + "/unassign";

export const GET_CAMERAS_TO_UNASSIGN = baseCameraServiceUrl + "/get-assigned-camera-esn";

export const CHECK_CAMERA_ACTIVATION_STATUS = baseCameraServiceUrl + "/swap/camera-status/";

export const GET_CAMERA_BY_ESN_FOR_SWAP = baseCameraServiceUrl + "/swap/camera/";

export const GET_CAMERA_FOR_SWAP_V2 = baseCameraServiceUrl + "/swap/search-camera";

export const GET_CAMERA_BY_ACCOUNT_FOR_SWAP = baseCameraServiceUrl + "/swap/uaccountid/";

export const SWAP_CAMERA = baseCameraServiceUrl + "/swap/camera";

export const SEARCH_CONTROL_UNIT = baseCameraServiceUrl + "/swap/search-control-unit";

export const SWAP_CONTROL_UNIT = baseCameraServiceUrl + "/v2/swap/control-unit";

export const SWAP_CAMERA_V2 = baseCameraServiceUrl + "/v2/swap/camera";

export const GET_WORK_TICKET_DETAILS = baseCameraServiceUrl + "/getRelatedWorkTickets/";

export const GET_DECOMM_CAMERAS = baseCameraServiceUrl + "/get-cameras-for-decomm";

export const DECOMM_CAMERAS = baseCameraServiceUrl + "/decommission-cameras";

export const CREATE_CAMERA = baseCameraServiceUrl + "/v2/create-camera/";

export const GET_CREATE_CAMERAS = baseCameraServiceUrl + "/get-creatable-cameras";

export const GET_CONTROL_UNITS_FOR_VTU = baseCameraServiceUrl + "/cameras/get-control-units";

export const GET_STANDALONE_CAMERAS_FOR_CONTROL_UNIT = baseCameraServiceUrl + "/cameras/get-standalone-cameras";

export const ASSIGN_CONTROL_UNIT = baseCameraServiceUrl + "/cameras/v2/assign-control-unit";

export const ASSIGN_STANDALONE_CAMERA = baseCameraServiceUrl + "/cameras/v2/assign-camera";

export const ASSIGN_BUNDLE_CAMERAS = baseCameraServiceUrl + "/cameras/v2/assign-bundle";

export const GET_CAMERA_FEATURE_RELATED_WORKTICKETS = baseCameraServiceUrl + "/camera-feature-work-tickets/";
