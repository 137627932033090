import { getRequest, postRequest } from "../RestServices";
import { ReportsCameraData, ReportsDeviceData, ReportsOpportunityHistory, ReportsVTUData } from "../../api/reports/url";
import { CFWARRANTYSTATUS } from "../../api/VTU/url";

export const opportunityHistory = async (payload) => {
  try {
    const _response = await postRequest(ReportsOpportunityHistory, payload);
    const _OpportunityDetails = _response?.data?.data ?? [];
    return { rows: _OpportunityDetails };
  } catch (err) {
    return err;
  }
};

export const fetchProductInfo = async (payload) => {
  try {
    return await getRequest(`${ReportsOpportunityHistory}/${payload}/productdetails`);
  } catch (e) {
    return e;
  }
};
const vehicleResponse = {
  status: 200,
  data: {
    data: {
      vehicleData: [
        {
          technicalRefId: "0001505992.0003038698",
          status: null,
          errorMessage: null,
          workTicketNumber: null,
          inboundShipmentDate: null,
          activationDate: null,
          vehicleTrackingId: "9000000000505034920",
          outboundShipmentDate: "2023-03-15 14:06:22.4272394",
          outboundShipmentEffectiveDate: "2023-03-15 12:47:58.3296108",
          firstReportedDate: null,
          installDate: null,
          installationMethod: null,
          billingStartDate: null,
          location: "AUS",
          phoneNumber: "97122452067",
          selfCheckIn: false,
          esn: "767322357",
        },
        {
          technicalRefId: "0001505992.0003038698",
          status: null,
          errorMessage: null,
          workTicketNumber: null,
          inboundShipmentDate: null,
          activationDate: null,
          vehicleTrackingId: "9000000000505034921",
          outboundShipmentDate: "2023-03-15 14:09:27.6137976",
          outboundShipmentEffectiveDate: "2023-03-15 12:47:58.3426128",
          firstReportedDate: null,
          installDate: null,
          installationMethod: null,
          billingStartDate: null,
          location: "AUS",
          phoneNumber: "97122452068",
          selfCheckIn: false,
          esn: "767322358",
        },
      ],
      cameraData: [],
    },
  },
};
export const retrieveDeviceData = async (payload) => {
  try {
    return await postRequest(ReportsDeviceData, payload);
    // return vehicleResponse;
  } catch (err) {
    return err;
  }
};

export const retrieveVtuPageData = (payload, pageNo = 0, pageSize = 10) =>
  new Promise((res, rej) => {
    postRequest(`${ReportsVTUData}?pageNo=${pageNo}&pageSize=${pageSize}`, payload)
      .then((data) => res(data?.data?.data))
      .catch(({ response }) => rej(response.data));
  });

export const retrieveCameraPageData = (payload, pageNo = 0, pageSize = 10) =>
  new Promise((res, rej) => {
    postRequest(`${ReportsCameraData}?pageNo=${pageNo}&pageSize=${pageSize}`, payload)
      .then((data) => res(data?.data?.data))
      .catch(({ response }) => rej(response?.data));
  });

export const checkCFStatus = (payload) =>
  new Promise((res, rej) => {
    postRequest(CFWARRANTYSTATUS, payload)
      .then((data) => res(data?.data?.data))
      .catch(({ response }) => rej(response?.data));
  });
