import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { DrillDownMenu } from "./DrillDownMenu";
import SvgIcon from "../../core/SvgIcon";
import { CustomIconWrapper } from "../../../pages/HomePage/HomePage.style";
import { addTabDropdown } from "../../../pages/integrationConfig";
import { messageBus } from "../../../utility/messageBus";
import { UPDATE_QUICK_LINKS } from "../../../constants/ApplicationConstants";
const statusStyle = {
  position: "absolute",
  left: "0px",
  top: "30px",
  width: "20px",
  height: "20px",
};
const AddWrapper = styled.div`
  position: relative;
  height: 10px;
  margin: 17px 21px;
`;
const Wrapper = styled.button`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0;
  background-color: inherit;
  padding: 0;
  z-index: 1;
`;

const Add = styled.div`
  font-weight: normal;
  line-height: 1.2;
  border-bottom: 1px solid ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.text};
  font-size: 14px;
`;
export const AddTab = ({ onClick }) => {
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(addTabDropdown);

  const handleUpdateQuickLinks = (links) => {
    setMenuItems(links);
  };

  useEffect(() => {
    const _unsubscribeQuickLinks = messageBus.on(UPDATE_QUICK_LINKS, handleUpdateQuickLinks);
    return () => {
      _unsubscribeQuickLinks();
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener("click", blurMenu);
  }, []);
  const blurMenu = () => {
    setOpen(false);
  };
  const onAddNewClick = () => {
    setTimeout(() => setOpen(true), 200);
  };

  const sendTabData = (e) => {
    return { tabLabel: e.name, componentName: e.id };
  };
  return (
    <AddWrapper>
      <>
        <Wrapper aria-label="Add a Tab" data-testid="addNewTab" role="button" onClick={() => onAddNewClick()}>
          <CustomIconWrapper spanheight={"16px"} noMargin={true} alt="ZoomIn">
            <SvgIcon type={"zoom_in"} />
          </CustomIconWrapper>
          {/* <IconWrapper data={zoomIn} type="image/svg+xml" alt="add a tab" /> */}
          {/* <IconContainer>
              <SvgIcon type={'zoom_in'} />
            </IconContainer> */}
          <Add>Add</Add>
        </Wrapper>
        <DrillDownMenu open={open} items={menuItems} onClick={(e) => onClick(sendTabData(e))} right="auto" width="165px" />
      </>
    </AddWrapper>
  );
};
