import React, { useContext, useState, useRef } from "react";
import { CustomSLButton, SLNotification } from "../../components/core/utility";

import { generate } from "shortid";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { css } from "@emotion/react";
import { handleAddPage } from "./utils";
import { VehicleListTableMetadata } from "./VehicleListTable";
import { C4SContext } from "../../context/c4sContext";
import { uploadCsv } from "../../services/C4S Decommission - Replacement/uploadCsv";
import { hasPrivilege } from "../../../utility/privileges";
import { Privileges } from "../../../constants/PrivilegeConstants";
import useOutsideClick from "../../customHook/use-outside-click";

const customSpinner = css`
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  // width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  opacity: 0.8;
  & svg {
    width: 6%;
  }
  .CircularProgressbar-path {
    stroke: green;
  }
  .CircularProgressbar-text {
    fill: #000000;
    font-size: 25px;
    font-weight: bold;
  }
`;

export const UploadFile = ({ getVehicleList }) => {
  const [isSpinnerShown, setIsSpinnerShown] = useState(false);
  const [spinnerValue, setSpinnerValue] = useState(0);
  const uploadRef = useRef(null);
  const { isButtonDisabled, cspDetails, notification, limitWTCreation } = useContext(C4SContext);
  let spinnerPercentage;

  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  const clearSpinner = () => {
    setIsSpinnerShown(false);
    setSpinnerValue(0);
  };

  const onSelectFile = (e) => {
    let cspCaseDetails = { ...cspDetails.cspDetails };
    const cspSessionData = JSON.parse(sessionStorage.getItem("CSPDetails"));
    const providerOrder = cspSessionData?.CSP.length ? cspSessionData?.CSP.filter((csp) => csp.DefaultCSP === "1")[0]?.Provider_Order : "";
    cspCaseDetails.contractAccountId = cspSessionData.CONTRACT_ACCOUNT_ID ? cspSessionData.CONTRACT_ACCOUNT_ID[0] : "";
    cspCaseDetails.caseNumber = cspSessionData.CASEDetails.length
      ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0]?.CaseNumber
      : "";
    const erpBusinessPartnerId = cspSessionData.ERP_Partner_Number ? cspSessionData.ERP_Partner_Number : "";
    let productInfo = [];
    if (cspCaseDetails.product) {
      let productsArr = cspCaseDetails?.product.map((item) => ({
        sapProductId: item.SAPProductID,
        quantity: item.Quantity ? Number(item.Quantity) : 0,
        orderDetailType: item.OrderDetailType,
        techRefId: item.TechRefId,
        bundleId: item.BundleId,
        providerOrder: providerOrder,
        erpBusinessPartnerId: erpBusinessPartnerId,
      }));
      let productMap = new Map();
      for (const product of productsArr) {
        const key = `${product.sapProductId}-${product.techRefId}`;
        if (productMap.has(key)) {
          productMap.get(key).quantity = String(Number(productMap.get(key).quantity) + Number(product.quantity));
        } else {
          productMap.set(key, { ...product });
        }
      }
      //convert productsMap to array
      productInfo = Array.from(productMap.values());
      delete cspCaseDetails.product;
    }
    delete cspCaseDetails.cspData;
    delete cspCaseDetails.salesOrgName;
    delete cspCaseDetails.erpPartnerNumber;
    const file = e.target.files[0];
    if (file) {
      if (file.type === "text/csv") {
        notification.setNotification(null);
        const data = new FormData();
        data.append("file", file);
        data.append("cspAccountInfo", JSON.stringify(cspCaseDetails));
        data.append("productInfo", JSON.stringify(productInfo));
        uploadFile(data);
      } else {
        notification.setNotification(
          <SLNotification key={generate()} type="error" title="The file must be in .csv format" fullBleed={true} ref={clickRef} />
        );
      }
    }
    uploadRef.current.value = "";
  };

  const uploadFile = async (data) => {
    setIsSpinnerShown(true);
    let progress = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentComplete = Math.floor((loaded * 100) / total);
        setSpinnerValue(percentComplete);
      },
    };
    let response = await uploadCsv(data, progress);
    if (response.status === 200) {
      response.data.data.rowLimitExceded
        ? notification.setNotification(
            <SLNotification
              key={generate()}
              type="error"
              title="Files cannot have more than 100 lines. First 100 lines have been uploaded."
              ref={clickRef}
            />
          )
        : notification.setNotification(null);
      // handleAddPage(VehicleListTableMetadata.id);
      getVehicleList(cspDetails.cspDetails.accountId);
    } else {
      notification.setNotification(<SLNotification key={generate()} type="error" title="Unable to upload file" fullBleed={true} ref={clickRef} />);
    }
    clearSpinner();
  };

  return (
    <>
      {isSpinnerShown && (
        <div css={customSpinner}>
          <CircularProgressbar value={spinnerValue} text={`${spinnerValue}%`} styles={{ path: { stroke: spinnerValue > 49 ? `green` : `orange` } }} />
        </div>
      )}
      <input ref={uploadRef} type="file" style={{ display: "none" }} onChange={onSelectFile} accept=".csv" />
      <CustomSLButton
        size="large"
        aria-label="Upload File"
        role="button"
        type="button"
        aria-disabled={
          isButtonDisabled.isButtonDisabled || limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)
        }
        disabled={isButtonDisabled.isButtonDisabled || limitWTCreation.limitWTCreation || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
        onClick={() => uploadRef.current.click()}
      >
        Upload File
      </CustomSLButton>
    </>
  );
};

export default UploadFile;
