export const OppStatus = Object.freeze({
  IN_PROGRESS: "In Progress",
  M1_IN_PROGRESS: "M1 In Progress",
  M2_IN_PROGRESS: "M2 In Progress",
  RAC_IN_PROGRESS: "RAC In Progress",
  OC_IN_PROGRESS: "OC In Progress",
  COMPLETED: "Completed",
  M1_COMPLETED: "M1 Completed",
  M1COMPLETED: "M1Completed",
  M2_COMPLETED: "M2 Completed",
  RAC_COMPLETED: "RAC Completed",
  OC_COMPLETED: "OC Completed",
  OR_COMPLETED: "OrderRewriteCompleted",
  FAILED: "Failed",
  M1_FAILED: "M1 Failed",
  M2_FAILED: "M2 Failed",
  RAC_FAILED: "RAC Failed",
  OC_FAILED: "OC Failed",
  CANCELLATION_IN_PROGRESS: "CancellationInProgress",
  CANCELLATION_COMPLETED: "CancellationCompleted",
  CANCELLATION_FAILED: "CancellationFailed",
  BUYOUT_START: "BuyoutStart",
  BUYOUT_COMPLETE: "BuyoutComplete",
  BUYOUT_FAIL: "BuyoutFail",
  BLENDED_CANCELLATION_FAILED: "BlendedCancellationFailed",
  BLENDED_CANCELLATION_SUCCEEDED: "BlendedCancSucceeded",
  BLENDED_CANCELLATION_IN_PROGRESS: "BlendedCancellationInProgress",
});

export const inProgressOpportunitiesStatus = [
  OppStatus.IN_PROGRESS,
  OppStatus.M1_IN_PROGRESS,
  OppStatus.M2_IN_PROGRESS,
  OppStatus.RAC_IN_PROGRESS,
  OppStatus.OC_IN_PROGRESS,
  OppStatus.CANCELLATION_IN_PROGRESS,
  OppStatus.BUYOUT_START,
  OppStatus.BLENDED_CANCELLATION_IN_PROGRESS,
];
export const completedOpportunityStatus = [
  OppStatus.COMPLETED,
  OppStatus.M1_COMPLETED,
  OppStatus.M1COMPLETED,
  OppStatus.M2_COMPLETED,
  OppStatus.RAC_COMPLETED,
  OppStatus.OC_COMPLETED,
  OppStatus.OR_COMPLETED,
  OppStatus.CANCELLATION_COMPLETED,
  OppStatus.BUYOUT_COMPLETE,
  OppStatus.CANCELLATION_IN_PROGRESS,
  OppStatus.BLENDED_CANCELLATION_SUCCEEDED,
];
export const failedOpportunitiesStatus = [
  OppStatus.FAILED,
  OppStatus.M1_FAILED,
  OppStatus.M2_FAILED,
  OppStatus.RAC_FAILED,
  OppStatus.OC_FAILED,
  OppStatus.CANCELLATION_FAILED,
  OppStatus.BUYOUT_FAIL,
  OppStatus.BLENDED_CANCELLATION_FAILED,
];
export const completedOppFilterFn = ({ oppStatus }) => completedOpportunityStatus.includes(oppStatus);
export const inProgressOppFilterFn = ({ oppStatus }) => inProgressOpportunitiesStatus.includes(oppStatus);
export const failedOppFilterFn = ({ oppStatus }) => failedOpportunitiesStatus.includes(oppStatus);
