import { COLUMN_WIDTHS } from "../../constants/ApplicationConstants";

export const INFO_MODAL_COLUMNS = [
  {
    key: "Product Name",
    value: "productName",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Technical Ref Id",
    value: "technicalRefId",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Ordered Qty",
    value: "orderedQty",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Shipped Qty",
    value: "shippedQty",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Installed Qty",
    value: "installedQty",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Billed Qty",
    value: "billedQty",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
];
