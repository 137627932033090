import { COLUMN_WIDTHS } from "../../constants/ApplicationConstants";
import { errorBody } from "./errorModal";

export const REPORTS_OPPORTUNITY_HISTORY_COL = [
  {
    key: "Opportunity ID",
    value: "salesForceOpportunityId",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Account ID",
    value: "salesForceAccountId",
    filterType: "Status",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Customer Name",
    value: "salesForceAccountName",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Account Number",
    value: "salesForceAccountNo",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "VZC Number",
    value: "vzcnumber",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },

  {
    key: "Opportunity Status",
    value: "stage",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.lsmall,
    containsImage: true,
  },
  {
    key: "ERP BP Number",
    value: "bpNumber",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Opportunity Processed Date",
    value: "opportunityCreatedDate",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
    isDate: true,
  },
  {
    key: "Opportunity Closed Date",
    value: "opportunityCloseDate",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    isDate: true,
    dateFormat: "MM/DD/YYYY",
  },
  {
    key: "Type",
    value: "orderType",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Sub Type",
    value: "orderSubType",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Error Message",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    isCustomFilter: false,
    value: "errorMessage",
    width: COLUMN_WIDTHS.small,
    customBodyCallback: errorBody,
  },
  {
    key: "Billing Trigger",
    value: "contractLevelBillingTrigger",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Sales Confirmed Date",
    value: "salesConfirmedDate",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
    isDate: true,
  },
  {
    key: "ERP PO Number",
    value: "erpPoNumber",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Self Install",
    value: "selfInstall",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
];
