import { postRequest } from "../RestServices";
import * as urls from "../../api/orders/url";
import { getProperty } from "../../utility/getProperty";

export const opportunityHistory = async (payload) => {
  try {
    const _response = await postRequest(urls.OpportunityHistory, payload);
    const _OpportunityDetails = _response?.data?.data ?? [];
    return { rows: _OpportunityDetails };
  } catch (err) {
    return err;
  }
};
