import { ADD_PAGE, OPEN_TAB_EVENT, RESET_TAB, UPDATE_TAB } from "../constants/ApplicationConstants";
import { leftNavigationMenus } from "../pages/integrationConfig";
import * as pageList from "../pages/pageList";
import { messageBus } from "./messageBus";
import { hasPrivilege } from "./privileges";

const pageMapping = new Map();
const routeMapping = new Map();

/* eslint-disable */

Object.keys(pageList).forEach((item) => {
  const pageListItem = pageList[item];
  pageMapping.set(pageListItem?.id, pageList[item]);
  routeMapping.set(pageListItem?.route, pageList[item]);
});

/* eslint-enable */

export function getPageMetadataById(id) {
  return pageMapping.get(id);
}

export function getPageMetadataByRoute(route) {
  return routeMapping.get(route);
}

export function getRoute() {
  let route = window.location.hash;
  const paramIndex = route.indexOf("?", 1);
  route = route.substring(1, paramIndex > 0 ? paramIndex : route.length);
  if (route.endsWith("/")) route = route.substring(0, route.length - 1);
  return route;
}

export function getPropsFromAddress() {
  const route = window.location.hash;
  if (route.includes("?")) {
    let parameters = route.substring(route.indexOf("?") + 1);
    if (parameters.endsWith("/")) parameters = parameters.substring(0, parameters.length - 1);

    parameters = parameters.split("&");
    const parameterObj = {};
    parameters.forEach((item) => {
      const [key, value] = item.split("=");
      parameterObj[key] = value;
    });
    return parameterObj;
  }
  return null;
}

export function getPageById(component, baseComponent) {
  let baseMetaData = getPageMetadataById(baseComponent);
  if (baseMetaData.id === component) {
    return baseMetaData.component;
  } else {
    return baseMetaData?.subPages?.find((item) => item.id === component).component;
  }
}

export function getPageNameById(component, baseComponent) {
  let baseMetaData = getPageMetadataById(baseComponent);
  if (baseMetaData.id === component) {
    return baseMetaData.name;
  } else {
    return baseMetaData.subPages.find((item) => item.id === component).name;
  }
}

export function updateAddress(text) {
  history.replaceState(null, "", text);
}

export function addPage(id, props) {
  messageBus.trigger(ADD_PAGE, { id, props });
}

export function resetTab() {
  messageBus.trigger(RESET_TAB);
}

export function redirectToPageInAddress(updateTab = false) {
  const route = getRoute();
  if (route) {
    const metadata = getPageMetadataByRoute(route);
    const props = getPropsFromAddress();
    if (metadata.directlyAccessible === null || metadata.directlyAccessible === undefined) {
      metadata.directlyAccessible = true;
    }
    if (metadata && metadata.directlyAccessible && !metadata?.disabled) {
      if (!metadata.privileges || (metadata.privileges && hasPrivilege(metadata.privileges))) {
        let tab = { tabLabel: metadata.name, componentName: metadata.id, url: window.location.hash.substring(1, window.location.hash.length) };
        if (props) tab.props = props;
        if (updateTab) {
          messageBus.trigger(UPDATE_TAB, tab);
        } else {
          messageBus.trigger(OPEN_TAB_EVENT, tab);
        }
        // updateAddress(`/#${metadata.route}`);
        return;
      }
    }
  }
  updateAddress("/");
}

export function openTab(menu, props) {
  updateAddress(`/#${menu.route}`);
  let tab = { tabLabel: menu.name, componentName: menu.id, url: menu.route, props };
  messageBus.trigger(OPEN_TAB_EVENT, tab);
}

let enabledMenusCache = [];
export function getAllEnabledItems() {
  if (enabledMenusCache.length === 0) {
    leftNavigationMenus?.forEach((item) => {
      item.subMenus.forEach((subMenu) => {
        enabledMenusCache.push(subMenu);
      });
    });
  }
  return enabledMenusCache;
}
getAllEnabledItems();

export function isIframe(window) {
  return window.location !== window.parent.location;
}
