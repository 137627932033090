/* istanbul ignore file */
import { StyledGridWrapper } from "../Utility";
import styled from "@emotion/styled";
import { Grid } from "@vds/grids";

export const Wrapper = styled(StyledGridWrapper)`
  //margin-bottom: 50px;
  /* > div > div > div {
    width: 100%;
  } */
  .p-datatable-scrollable-wrapper {
    height: auto;
    font-size: ${(props) => props.fontSize};
    font-family: "Verizon-NHG-eDS";
    color: ${({ theme }) => theme.text};
  }
  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    overflow: ${(props) => (props.frozen ? "hidden" : "visible")};
    padding-bottom: 200px;
    margin-bottom: -200px;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
  }
  .p-datatable-scrollable-header {
    padding-right: 15px;
  }
  .p-checkbox {
    width: 16px;
    height: 16px;
    display: flex;
  }
  .p-datatable .p-datatable-tbody > tr {
    background: ${({ theme }) => theme.background1};
    outline: none;
    // border-left: 6px solid transparent;
    // &:first-child {
    //   border-left: 4px solid transparent;
    // }
  }
  .p-datatable .p-datatable-frozen-view .p-datatable-tbody > tr.p-datatable-emptymessage {
    display: none;
  }
  .p-datatable .p-datatable-unfrozen-view .p-datatable-tbody > tr.p-datatable-emptymessage:before {
    content: "No records found";
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.background1};
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover .p-radiobutton-box:hover {
    box-shadow: 0 0 0 0.0625rem #000000;
    border-color: #000;
    transition: none;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.background};
  }
  input.p-inputtext.p-component.p-column-filter {
    width: 100%;
    left: auto !important;
  }
  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #000;
    background: ${({ theme }) => theme.text};
  }
  .p-checkbox .p-checkbox-box {
    height: 16px;
    border: 1px solid #000;
  }
  .p-datatable-thead {
  }
  .p-datatable-frozen-view .p-datatable-scrollable-header-box {
    z-index: 2;
  }
  .p-datatable-scrollable-header-box {
    top: 112px;
    z-index: 1;
    width: -webkit-fill-available;
    min-width: 100%;
  }
  .p-datatable-thead th {
    background: ${({ theme }) => theme.background} !important;
  }
  .p-datatable-unfrozen-view {
    position: ${(props) => (props.frozen ? "absolute" : "static")};
    width: -webkit-fill-available !important;
  }
  .p-datatable-unfrozen-view .p-datatable-scrollable-body {
    overflow: auto !important;
  }
  .p-contextmenu {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.background1};
    position: fixed;
    width: 146px;
    font-size: 12px;
    display: block !important;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: ${({ theme }) => theme.background6};
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: ${({ theme }) => theme.background1};
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr.p-selectable-row:nth-child(2n):not(.p-highlight):hover {
    background: ${({ theme }) => theme.background};
  }
  .p-menuitem {
    font-size: 12px;
  }
  .p-menuitem-text {
    color: ${({ theme }) => theme.text} !important;
  }
  .p-contextmenu .p-menuitem-link {
    padding: 0.25rem 1.5rem;
  }
  .p-datatable .p-column-header-content {
    display: flex !important;
    align-items: center;
  }
  .p-datatable .p-datatable-header {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    border: none;
    padding: 0;
    padding-bottom: ${({ gridTitle }) => (gridTitle ? "16px" : "10px")};
    border-bottom: 1px solid ${({ theme }) => theme.text};
    width: ${({ autoResizeHead }) => (autoResizeHead ? "auto" : "calc(100vw - 135px)")};
    z-index: 2;
    padding-top: 0px;
  }
  .p-datatable {
    min-height: 115px;
  }
  .p-datatable .p-datatable-thead > tr > th {
    &:first-child {
      // border-left: 4px solid white;
    }
  }
  .p-datatable .p-datatable-tbody > tr > td {
    border-bottom: 1px solid ${({ theme }) => theme.border5};
    white-space: ${(props) => props.whiteSpace};
  }
  .p-datatable .p-datatable-tbody > tr > td,
  .p-datatable .p-datatable-thead > tr > th {
    font-size: ${(props) => props.fontSize};
    padding: 0px 8px !important;
    color: ${({ theme }) => theme.text} !important;
    p,
    span:not([role="switch"]) {
      margin: 0;
      background: inherit;
    }
    ,
    span:not([role="button"]) {
      margin: 0;
      background: unset;
    }
    a {
      color: ${({ theme }) => theme.text} !important;
      border-bottom-color: ${({ theme }) => theme.text} !important;
      height: auto;
    }
  }
  .p-datatable .p-datatable-thead > tr > th.columnCheckBox {
    display: flex;
    padding-left: 8px !important;
  }
  .p-datatable .p-datatable-thead > tr > th.columnCheckBox > div > span.p-column-title {
    display: none;
  }
  .p-column-title {
    color: ${({ theme }) => theme.text} !important;
    display: block;
    margin-right: 1rem !important;
    /* width: 80%; */
  }
  .p-datatable-resizable .p-datatable-thead > tr > th {
    overflow: visible;
    &:first-child {
      // border-left: 4px solid transparent;
      // padding-left: 12px !important;
    }
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-frozen-view .p-datatable-tbody > tr:hover {
    // border-left: 4px solid #d52b1e !important;
    // &:first-child {
    //   border-left: 4px solid #d52b1e !important;
    // }
  }
  span.p-paginator-pages {
    display: flex;
  }
  .p-datatable .p-paginator-bottom {
    flex-wrap: wrap;
    padding-top: 3px;
    height: max-content;
    padding-left: 0px;
    position: relative;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
  }
  th.p-sortable-column.p-resizable-column:hover {
    // border-right: 2px solid #ababab;
    background-color: ${({ theme }) => theme.background5} !important;
  }
  th.p-sortable-column.p-resizable-column {
    // border-right: 2px solid transparent;
  }
  .p-paginator {
    justify-content: flex-start;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    height: 50px;
  }
  .p-paginator-current {
    align-items: end;
    padding: 0 0.5rem 5px;
    color: ${({ theme }) => theme.text};
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
  .p-link:focus {
    box-shadow: none !important;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 0;
    outline: none !important;
  }
  .p-dropdown:not(.p-disabled):hover,
  .p-dropdown:not(.p-disabled).p-focus {
    border-color: black;
    box-shadow: none !important;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: transparent;
    border-left: 4px solid #d52b1e;
    padding-left: 12px;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    border-bottom: 1px solid lightgrey;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: transparent;
    border-left: 4px solid black;
    padding-left: 12px;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0;
    border: 1px solid black;
  }
  .p-paginator .p-dropdown,
  .p-paginator .p-dropdown:hover {
    width: 70px !important;
    border-radius: 0 !important;
    border: 1px solid lightgrey !important;
    border-bottom-color: black !important;
    &.p-focus {
      border-color: black !important;
    }
  }
  .p-dropdown .p-dropdown-trigger,
  .p-dropdown-item,
  .p-dropdown-label,
  .p-link {
    width: 2.5rem;
    color: ${({ theme }) => theme.text} !important;
  }
  .p-dropdown {
    height: 43px;
  }
  .p-checkbox-box {
    border-radius: 0;
  }
  .p-datatable-scrollable-footer {
    background: ${({ theme }) => theme.background};
  }
  .pi-sort-alt {
    visibility: visible;
  }
  .p-sortable-column:hover .p-sortable-column-icon,
  .p-sortable-column:hover .p-column-filter {
    visibility: visible;
  }
  .p-sortable-column-icon {
    /* position: absolute; */
    top: 8px;
    right: 10px;
    visibility: hidden;
  }
  .p-column-filter {
    visibility: hidden;
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    // background: white;
  }
  .p-datatable .p-datatable-tbody .p-selectable-row:nth-child(2n) {
    background: ${({ theme }) => theme.background};
  }
  .p-datatable .p-datatable-tbody > tr.p-selectable-row.p-highlight {
    outline: none;
    background: ${({ theme }) => theme.background9};
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
  }
  .p-selectable-row td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .p-paginator-left-content {
    position: relative;
    margin-right: 20px;
  }
  .p-paginator-right-content {
    position: relative;
    margin-top: -2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .p-paginator-right-content .selectedRecords-count {
    margin-right: 20px;
  }
  .p-datatable-frozen-view .p-datatable-scrollable-body {
    overflow-y: hidden !important;
  }
  .p-datatable-frozen-view .p-datatable-scrollable-body > table > .p-datatable-tbody > tr > td.columnCheckBox {
    padding-left: 4px !important;
  }
  .p-datatable-frozen-view .p-selectable-row {
    border-left: 4px solid transparent;
  }
  .p-inputtext {
    /* padding: 0 12px 0 0; */
    border: 0;
    font-size: 14px;
    border-radius: 0;
    /* border-bottom: 1px solid ${({ theme }) => theme.text}; */
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
  }
  .p-inputtext:enabled:hover {
    border-color: #d8dada;
  }
  .p-inputtext:enabled:focus {
    border: 0;
    box-shadow: 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.text};
  }
  .p-input-icon-left,
  .p-input-icon-right {
    width: 150px;
    display: flex;
  }
  .p-input-icon-left > i:first-of-type {
    left: auto;
    right: 0;
    z-index: 1;
    font-size: 12px;
    margin-top: -0.35rem;
    color: ${({ theme }) => theme.text};
  }
  .p-datatable .p-datatable-loading-overlay {
    background: transparent;
    z-index: 3;
    top: 25px;
  }
  .p-datatable-row-expansion {
    background: transparent !important;
  }
  .p-paginator-first {
  }
  .p-paginator-last {
  }
  .p-radiobutton .p-radiobutton-box.p-highlight,
  .p-radiobutton-box:hover {
    border-color: #000;
    background: ${({ theme }) => theme.text};
  }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #000;
    background: ${({ theme }) => theme.text};
    color: #000;
  }
`;

export const StyledGrid = styled(Grid)`
  /* width: 100%;
  max-width: 100%; */
  margin: 0;
  height: auto;
  background: ${({ theme }) => theme.background};
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  > div > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: auto;
    background: ${({ theme }) => theme.background};
    > div > div {
      max-width: 100%;
      width: 100%;
      background: ${({ theme }) => theme.background};
    }
  }
`;
