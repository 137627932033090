import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Col } from "@vds/grids";
import { OPPORTUNITY_HISTORY_COL } from "../../constants/OpportunityHistoryConstants";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { SLCheckBox, SLInput } from "../../components/core/Controls";
import { Loader } from "@vds/loaders";
import { generate } from "shortid";
import { opportunityHistory } from "../../services/orders/opportunityHistory";
import { addPage } from "../../utility/navigation";
import { Spacer, SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/Utility";
import { Body } from "@vds/typography/dist/cjs/components/Body";
import { MilestoneMetadata } from "./milestone";
import Completed from "../../assets/icons/Success.svg";
import InProgress from "../../assets/icons/Process.svg";
import Failed from "../../assets/icons/failed.svg";
import { OPPORTUNITY_ID } from "../../constants/ValidationConstants";
import { Button } from "@vds/buttons";
import { completedOppFilterFn, failedOppFilterFn, inProgressOppFilterFn } from "../../utility/Opportunity";
import { css } from "@emotion/react";
const LocationStyle = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`;

const CustomImage = styled.div`
  display: flex;
`;

const Box = styled.div`
  box-sizing: border-box;
  min-height: 205px;
  left: 163px;
  top: 168px;
  background: #fbfbfb;
  border: 1px solid #d8dada;
  border-radius: 5px;
  padding-bottom: 10px;
`;

export const CustomRow = styled(SLRowLayout)`
  margin: 0;
  max-width: 1000px;
  & .pl-0 {
    padding-left: 0px;
  }
`;
export const CustomCheckBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  padding-left: 10px;
  margin: 0;
  max-width: 1000px;
`;
export const StyledSLCheckBox = styled(SLCheckBox)`
  margin-bottom: 10px;
`;

export const StyledButton = css`
  width: 100%;
  &button ~ &span[class^="StyledChildWrapper"] {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const CreateOrder = () => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [startDate, setStartDate] = useState(new Date(Date.now()).toISOString().split(".")[0].split("T")[0].concat("T").concat("00:00:00"));
  const [endDate, setEndDate] = useState(new Date().toISOString().split(".")[0].split("T")[0].concat("T").concat("23:59:59"));
  const [startDisplayDate, setStartDisplayDate] = useState("");
  const [endDisplayDate, setEndDisplayDate] = useState("");
  const [opportunityId, setOpportunityId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [dateFlag, setDateFlag] = useState(false);
  const [oppFlag, setOppFlag] = useState(false);
  const [accFlag, setAccFlag] = useState(false);
  const [complete, setComplete] = useState(true);
  const [inProgress, setInProgress] = useState(true);
  const [failed, setFailed] = useState(true);
  const [opportunities, setOpportunities] = useState([]);
  const [oppErr, setOppErr] = useState(false);
  const [accErr, setAccErr] = useState(false);
  const [accSucc, setAccSucc] = useState(false);
  const [accErrText, setAccErrText] = useState("");
  const [oppSucc, setOppSucc] = useState(false);
  const [oppErrText, setOppErrText] = useState("");

  const formattedRows = (data) => {
    return [...(data || [])].map(({ salesConfirmedDate, creationTime, ...rest }) => ({
      salesConfirmedDate: salesConfirmedDate ? new Date(salesConfirmedDate) : "",
      creationTime: creationTime ? new Date(creationTime) : "",
      ...rest,
    }));
  };
  const getOpportunityHistory = async () => {
    const config = {
      startDate: !dateFlag ? startDate : "",
      endDate: !dateFlag ? endDate : "",
      opportunityID: opportunityId,
      accountID: accountId,
    };
    if (opportunityId === "" && accountId === "" && startDate === "" && endDate === "") {
      notify("Please enter details or select date range to search ", "error");
    } else if ((startDate === "" && endDate !== "") || (startDate !== "" && endDate === "")) {
      notify("Please select both start date and end date to search ", "error");
    } else if (
      (opportunityId !== "" && validateInput("oppId", opportunityId)) ||
      (accountId !== "" && validateInput("accId", accountId)) ||
      (startDate !== "" && endDate !== "")
    ) {
      setLoading(true);
      try {
        const response = await opportunityHistory(config);
        if (response?.response?.status === 404) {
          notify("Records Not Found!", "error");
          setOpportunities([]);
        } else if (response.rows) {
          setNotification(null);
          setOpportunities(response.rows);
          if (response.rows.length === 0) {
            notify("Opportunities not found!", "error");
          }
        } else {
          notify("Unexpected error", "error");
        }
      } catch (error) {
        notify("Unexpected error", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const handleHyperLinkClicked = (data, key) => {
    if (key == "Opportunity Status") {
      addPage(MilestoneMetadata.id, {
        opportunityId: data.superLibraOpportunityID,
        sfOppId: data.salesForceOpportunityID,
        opportunityType: data.type,
        subType: data.subType,
      });
    }
  };

  const renderImage = (data) => {
    if (data == "Completed") {
      return (
        <CustomImage>
          <img src={Completed} height="16px" width="16px" css={{ marginRight: "8px" }} />
          <Body primitive="span">{data}</Body>
        </CustomImage>
      );
    } else if (data == "Failed" || data == "M1 Failed" || data == "M2 Failed") {
      return (
        <CustomImage>
          <img src={Failed} height="16px" width="16px" css={{ marginRight: "8px" }} />
          <Body primitive="span">{data}</Body>
        </CustomImage>
      );
    } else {
      return (
        <CustomImage>
          <img src={InProgress} height="16px" width="16px" css={{ marginRight: "8px" }} />
          <Body primitive="span">{data}</Body>
        </CustomImage>
      );
    }
  };

  const selectStartDate = (event) => {
    setStartDisplayDate(event);
    var tzoffset = new Date(event).getTimezoneOffset() * 60000;
    var localISOTime = new Date(new Date(event) - tzoffset).toISOString().split(".")[0];
    setStartDate(localISOTime);
  };

  const selectEndDate = (event) => {
    setEndDisplayDate(event);
    var tzoffset = new Date(event).getTimezoneOffset() * 60000;
    var localISOTime = new Date(new Date(event) - tzoffset).toISOString().split(".")[0];
    var dateArr = localISOTime.split("T");
    let str = "23:59:59";
    var finalDate = dateArr[0].concat("T").concat(str);
    setEndDate(finalDate);
  };

  const initializeDate = () => {
    var startdate = new Date(Date.now()).toLocaleString().split(",");
    setStartDisplayDate(startdate[0]);
    var closedate = new Date().toLocaleString().split(",");
    setEndDisplayDate(closedate[0]);
    setEndDate(new Date().toISOString().split(".")[0].split("T")[0].concat("T").concat("23:59:59"));
    setStartDate(new Date(Date.now()).toISOString().split(".")[0].split("T")[0].concat("T").concat("00:00:00"));
  };
  useEffect(() => {
    initializeDate();
    getOpportunityHistory();
  }, []);

  const validateInput = (text, input) => {
    if (input.match(OPPORTUNITY_ID) && text === "oppId") {
      setOppErr(false);
      setOppErrText(null);
      setOppSucc(true);
      return true;
    } else if (input.match(OPPORTUNITY_ID) && text === "accId") {
      setAccErr(false);
      setAccErrText(null);
      setAccSucc(true);
      return true;
    } else if (text === "oppId") {
      setOppErr(true);
      setOppErrText("Enter a valid Opportunity id");
      return false;
    } else {
      setAccErr(true);
      setAccErrText("Enter a valid Account id");
      return false;
    }
  };

  const opportunityHandler = (event) => {
    setOpportunityId(event.target.value);
    if (event.target.value.trim() === "") {
      setDateFlag(false);
      setAccFlag(false);
      setOppSucc(false);
      setOppErr(false);
      setOppErrText(null);
    } else {
      setDateFlag(true);
      setAccFlag(true);
    }
  };

  const accountHandler = (event) => {
    setAccountId(event.target.value);
    if (event.target.value.trim() === "") {
      setOppFlag(false);
      setAccErr(false);
      setAccErrText(null);
      setAccSucc(false);
    } else {
      setEndDisplayDate("");
      setEndDate("");
      setStartDate("");
      setStartDisplayDate("");
      setOppFlag(true);
    }
  };

  const completeHandler = (e) => {
    setComplete(e.target.checked);
  };

  const failedHandler = (e) => {
    setFailed(e.target.checked);
  };

  const inprogressHandler = (e) => {
    setInProgress(e.target.checked);
  };

  const resetHandler = () => {
    setAccountId("");
    setOpportunityId("");
    setAccFlag(false);
    setDateFlag(false);
    setOppFlag(false);
    setComplete(true);
    setFailed(true);
    setInProgress(true);
    setAccErr(false);
    setOppErr(false);
    setAccErrText("");
    setOppErrText("");
    setAccSucc(false);
    setOppSucc(false);
    initializeDate();
  };

  useEffect(() => {
    checkBoxFilter(opportunities, inProgress, complete, failed);
  }, [opportunities, complete, inProgress, failed]);

  const checkBoxFilter = (opportunities, inProgress, complete, failed) => {
    if (!opportunities?.length) return;
    let completedOpportunities = [];
    let inProgressOpportunities = [];
    let failedOpportunities = [];
    if (complete) completedOpportunities = opportunities.filter(completedOppFilterFn);
    if (inProgress) inProgressOpportunities = opportunities.filter(inProgressOppFilterFn);
    if (failed) failedOpportunities = opportunities.filter(failedOppFilterFn);
    const newFilteredOpportunities = [...completedOpportunities, ...inProgressOpportunities, ...failedOpportunities].map((el) => {
      const status =
        el?.oppStatus === "BlendedCancSucceeded"
          ? "BC Completed"
          : el?.oppStatus === "BlendedCancellationFailed"
          ? "BC Failed"
          : el?.oppStatus === "BlendedCancellationInProgress"
          ? "BC InProgress"
          : el?.oppStatus;
      return { ...el, oppStatus: status };
    });
    setFilteredOpportunities(newFilteredOpportunities);
  };

  return (
    <LocationStyle>
      {notification}
      <Loader active={loading} />
      <Box>
        <SLGridLayout
          colSizes={{
            mobile: 1,
            tablet: 2,
            desktop: 3,
          }}
        >
          <CustomRow>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 6, desktop: 3 }}>
              <SLInput
                label="Opportunity Id"
                value={opportunityId}
                onChange={opportunityHandler}
                disabled={oppFlag}
                errorText={oppErrText}
                error={oppErr}
                success={oppSucc}
                maxLength="18"
              ></SLInput>
            </Col>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 6, desktop: 3 }}>
              <SLInput
                label="Account Id"
                value={accountId}
                onChange={accountHandler}
                errorText={accErrText}
                error={accErr}
                success={accSucc}
                disabled={accFlag}
                maxLength="18"
              ></SLInput>
            </Col>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 6, desktop: 3 }}>
              <SLInput
                type="calendar"
                label="Date Range From"
                data-testid="attr-start-calendar"
                minDate={new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)}
                maxDate={new Date(endDate)}
                onChange={selectStartDate}
                format="MM/DD/YYYY"
                value={startDisplayDate}
                disabled={dateFlag}
                errorText={""}
                error={false}
              ></SLInput>
            </Col>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 6, desktop: 3 }}>
              <SLInput
                type="calendar"
                label="Date Range To"
                data-testid="attr-end-calendar"
                minDate={new Date(startDate)}
                maxDate={new Date()}
                onChange={selectEndDate}
                value={endDisplayDate}
                format="MM/DD/YYYY"
                disabled={dateFlag}
                errorText={""}
                error={false}
              ></SLInput>
            </Col>
          </CustomRow>
          <CustomRow>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 6, desktop: 3 }}>
              <Spacer bottom="5px">
                <Body size="large" color="#000000" bold={true}>
                  Opportunity Status*
                </Body>
              </Spacer>
            </Col>
          </CustomRow>
          <CustomRow>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
              <CustomCheckBoxRow>
                <Col className="pl-0" colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
                  <StyledSLCheckBox type="checkbox" selected={complete} onChange={completeHandler}>
                    Completed
                  </StyledSLCheckBox>
                </Col>
                <Col className="pl-0" colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
                  <StyledSLCheckBox type="checkbox" selected={inProgress} onChange={inprogressHandler}>
                    In progress
                  </StyledSLCheckBox>
                </Col>
                <Col className="pl-0" colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
                  <StyledSLCheckBox type="checkbox" selected={failed} onChange={failedHandler}>
                    Failed
                  </StyledSLCheckBox>
                </Col>
              </CustomCheckBoxRow>
            </Col>
            <Col className="pl-0" colSizes={{ mobile: 2, tablet: 6, desktop: 3 }}>
              <Button css={StyledButton} size="large" onClick={getOpportunityHistory}>
                Search
              </Button>
            </Col>
            <Col className="pl-0" colSizes={{ mobile: 2, tablet: 6, desktop: 3 }}>
              <Button css={StyledButton} secondary={true} size="large" onClick={resetHandler}>
                Reset
              </Button>
            </Col>
          </CustomRow>
        </SLGridLayout>
      </Box>
      <br></br>
      <GridComponent
        isSelectionEnabled={false}
        isExportEnabled={true}
        noDataMessage="Opportunity Report is Empty"
        rows={formattedRows(filteredOpportunities)}
        hyperLinkClicked={handleHyperLinkClicked}
        renderDataWithImage={renderImage}
        columns={OPPORTUNITY_HISTORY_COL}
        title="Opportunity-History Data"
      />
    </LocationStyle>
  );
};
export default CreateOrder;
