import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexColumn, FlexRow, Spacer, SmallText, SLNotification } from "../../components/core/utility";
import Icon from "@vds/icons";
import { CustomDropdown, CustomInput } from "./AddAVehicle";
import { C4SContext } from "../../context/c4sContext";
import { generate } from "shortid";
import _ from "lodash";
import { getFeatureList } from "../../services/C4S Decommission - Replacement/getFeatureList";
import { Loader } from "@vds/loaders";
import { getFullName, INDIVIDUAL_RETURN_TYPE_LIST } from "./utils";
import searchIcon from "../../assets/icons/search_blk.png";
import { PHONE_REGEX } from "../../constants/C4SValidationConstants";
import { materialIdSearch } from "../../services/C4S Decommission - Replacement/mmidSearch";
import { getSapIdList } from "../../services/C4S Decommission - Replacement/getSapIdList";
import { MultiSelect } from "primereact/multiselect";
import styled from "styled-components";
import { ESNS_REGEX, LETTERS_SC_REGEX } from "../../constants/ValidationConstants";
import { addReturnItem } from "../../services/C4S Decommission - Replacement/addReturnItem";
import useOutsideClick from "../../customHook/use-outside-click";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export const CustomisedInput = styled(MultiSelect)`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  width: 90%;
  // height: 2.75rem;
  box-shadow: none !important;
  border-radius: 0;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 8px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000 !important;
    background-color: transparent;
  }
  .p-multiselect-label {
    font-size: 0.75rem;
    white-space: wrap;
  }
`;

export const CustomNotification = styled(SLNotification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 3 1 0%;
    }
  }
`;

export const IndividualReturnHardwareModal = ({
  addReturnHardwareModal,
  setAddReturnHardwareModal,
  salesOrgId,
  returnHardwareMMIDTableList,
  setReturnHardwareMMIDTableList,
  editReturnHardwareMMID,
  typeMMIDList,
  setTypeMMIDList,
  uniqueRecordMapping,
  setUniqueRecordMapping,
}) => {
  const { cspDetails, techRefIdList, isGovernmentAcc, isSourceCSP } = useContext(C4SContext);
  const [type, setType] = useState({ value: "", error: false, errorText: "Please select a Type", success: false, requiredEsn: false });
  const [materialId, setMaterialId] = useState({ value: "", error: false, errorText: "Enter valid MMID", success: false });
  const [description, setDescription] = useState({ value: "", error: false, errorText: "Enter valid Item Description", success: false });
  const [notification, setNotification] = useState(null);
  const [isMMIDValidated, setIsMMIDValidated] = useState(false);
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const editReturnHardware = editReturnHardwareMMID && Object.keys(editReturnHardwareMMID)?.length ? true : false;
  const [mId, setMId] = useState("");
  const [materialContract, setMaterialContract] = useState({ sapId: "", contractId: "", contractName: "" });
  const [defaultReturnType, setDefaultReturnType] = useState([]);
  const [isTypeAndMMIDValidated, setIsTypeAndMMIDValidated] = useState(false);
  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  const [returnReason, setReturnReason] = useState({
    success: false,
    error: false,
    errorText: "Please Select Return Reason",
    reason: "",
    requiredEsn: false,
  });
  const [quantity, setQuantity] = useState({
    success: false,
    error: false,
    errorText: "Enter Quantity",
    value: "",
  });
  const [esns, setEsns] = useState({
    success: false,
    error: false,
    errorText: "",
    value: "",
  });
  const returnReasonList = [
    {
      reason: "Customer Lost (Lost)",
      requiredEsn: true,
    },
    {
      reason: "Lost in Shipment (Lost)",
      requiredEsn: true,
    },
    {
      reason: "Defective Device (Return)",
      requiredEsn: false,
    },
    {
      reason: "Incorrect Equipment (Return)",
      requiredEsn: false,
    },
    {
      reason: "Partial Cancellation",
      requiredEsn: true,
    },
    {
      reason: "Full Cancellation",
      requiredEsn: false,
    },
    {
      reason: "Error with Order",
      requiredEsn: false,
    },
  ];

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setAddReturnHardwareModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    !isMMIDValidated && setIsFieldDisabled(true);
    materialId.success &&
    quantity.success &&
    type.success &&
    description.value &&
    isMMIDValidated &&
    returnReason.success &&
    !isTypeAndMMIDValidated &&
    (type.requiredEsn ? esns.success : true) &&
    !esns.error
      ? setIsSaveBtnDisabled(false)
      : setIsSaveBtnDisabled(true);
  }, [
    materialId.success,
    description.success,
    quantity.success,
    type.success,
    isMMIDValidated,
    returnReason.reason,
    esns.success,
    type.value,
    isTypeAndMMIDValidated,
    esns.error,
  ]);

  useEffect(() => {
    if (editReturnHardware) {
      handleFields("type", editReturnHardwareMMID?.type);
      handleFields("MMID", editReturnHardwareMMID?.materialId);
      setMId(editReturnHardwareMMID?.mId);
      handleFields("Return Reason", editReturnHardwareMMID?.returnReason);
      handleFields("description", editReturnHardwareMMID?.itemDescription);
      handleFields("ESNs", editReturnHardwareMMID?.esn);
      handleFields("Quantity", editReturnHardwareMMID?.quantity);
      setIsFieldDisabled(false);
      setIsMMIDValidated(true);
    }
  }, []);

  useEffect(() => {
    if (materialId.success && type.success) {
      let key = `${type.value} ${materialId.value}`;
      if (editReturnHardware) {
        if (uniqueRecordMapping.get(editReturnHardwareMMID?.id) !== key && typeMMIDList.has(key)) {
          setIsTypeAndMMIDValidated(true);
        } else {
          setIsTypeAndMMIDValidated(false);
        }
      } else {
        if (typeMMIDList.has(key)) {
          setIsTypeAndMMIDValidated(true);
        } else {
          setIsTypeAndMMIDValidated(false);
        }
      }
    }
  }, [materialId.value, type.value]);

  useEffect(() => {
    if (materialId.success) {
      setIsSearchDisabled(false);
    } else {
      setIsSearchDisabled(true);
    }
  }, [materialId.value]);

  const handleFields = (field, value) => {
    switch (field) {
      case "type": {
        // setIsMMIDValidated(false);
        if (value) {
          let selectedProcType = INDIVIDUAL_RETURN_TYPE_LIST.find((procType) => procType.code === value);
          setType({ value, error: false, errorText: "Please select a Type", success: true, requiredEsn: selectedProcType.requiredEsn });
        } else {
          setType({ value: "", error: true, errorText: "Please select a Type", success: false, requiredEsn: false });
        }
        if (!esns.value) {
          setEsns((prevState) => ({ ...prevState, success: false, error: false, value: "" }));
        }
        break;
      }
      case "MMID": {
        setIsMMIDValidated(false);
        if (value && value.length) {
          if (PHONE_REGEX?.test(value)) {
            setMaterialId({ value, error: false, errorText: "Enter valid MMID", success: true });
          } else {
            setMaterialId({ value, error: true, errorText: "Enter valid MMID", success: false });
          }
        } else {
          setMaterialId({ value: "", error: true, errorText: "Enter MMID", success: false });
        }
        break;
      }
      case "description": {
        if (value && value.length) {
          setDescription({ value, error: false, errorText: "", success: true });
        } else {
          setDescription({ value, error: true, errorText: "Enter valid Item Description", success: false });
        }
        break;
      }
      case "Quantity": {
        if (value) {
          setQuantity({ value, error: false, errorText: "", success: true });
        } else {
          setQuantity({ value, error: true, errorText: "Enter valid quantity", success: false });
        }
        break;
      }
      case "ESNs": {
        if (value) {
          if (LETTERS_SC_REGEX.test(value)) {
            setEsns((prevState) => ({ ...prevState, value, success: false, error: true, errorText: "Special characters are not allowed." }));
          } else if (ESNS_REGEX.test(value)) {
            setEsns((prevState) => ({ ...prevState, success: true, error: false, value }));
          } else {
            setEsns((prevState) => ({ ...prevState, success: false, error: true, errorText: "Please provide valid ESN(s)", value }));
          }
        } else if (!type.requiredEsn) {
          setEsns((prevState) => ({ ...prevState, success: false, error: false, errorText: "Please provide ESN(s)", value: "" }));
        } else {
          setEsns((prevState) => ({ ...prevState, success: false, error: true, errorText: "Please provide ESN(s)", value: "" }));
        }
        break;
      }
      case "Return Reason": {
        let reason = returnReasonList.find((reason) => reason.reason === value);
        setReturnReason((prevState) => ({ ...prevState, reason: reason.reason, success: true, requiredEsn: reason.requiredEsn }));
        // if (!esns.value) {
        //   setEsns((prevState) => ({ ...prevState, success: false, error: false, value: "" }));
        // }
        break;
      }
    }
  };

  const handleSave = () => {
    let returnHardware = {
      id: editReturnHardware ? editReturnHardwareMMID?.id : generate(),
      mId: mId,
      materialId: materialId.value,
      itemDescription: description.value,
      type: type.value,
      returnReason: returnReason.reason,
      esn: esns.value,
      quantity: quantity.value,
      sapId: materialContract?.sapId || "",
      contractId: materialContract?.contractId || "",
      contractName: materialContract?.contractName || "",
    };
    let newReturnHardwareMMIDList = _.cloneDeep(returnHardwareMMIDTableList).filter((record) => record.id !== returnHardware.id);
    let newList = [returnHardware, ...newReturnHardwareMMIDList];
    let newSet = new Set();
    let newMap = new Map();
    newList.map((returnItem) => {
      newSet.add(`${returnItem.type} ${returnItem.materialId}`);
      newMap.set(returnItem.id, `${returnItem.type} ${returnItem.materialId}`);
    });
    setTypeMMIDList(newSet);
    setUniqueRecordMapping(newMap);
    setReturnHardwareMMIDTableList(newList);
    setAddReturnHardwareModal(false);
  };

  const notify = (content, type, isNotificationWithTimer = true) => {
    setNotification(
      <>
        <CustomNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />
      </>
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const handleMMIDSearch = async () => {
    if (materialId.success) {
      setIsLoading(true);
      let data = {
        orderType: "",
        salesOrgId: salesOrgId,
        sapId: "",
        mmId: materialId.value,
        governmentAcc: isGovernmentAcc.isGovernmentAcc,
        type: "", //VZC42148-1573
        contractOverride: false,
        processFlow: "Return",
      };
      let response = await materialIdSearch(data, isSourceCSP.isSourceCSP, true);
      if (response?.status === 200) {
        response = response?.data?.data;
        if (response.mmidValid) {
          let defaultReturnProcessingType = INDIVIDUAL_RETURN_TYPE_LIST.filter((prosType) => prosType.code === response.defaultType);
          if (defaultReturnProcessingType.length) {
            setDefaultReturnType(defaultReturnProcessingType);
            handleFields("type", defaultReturnProcessingType[0]?.code ?? "");
          } else {
            setType({ value: "", error: false, errorText: "Please select a Type", success: false, requiredEsn: false });
            setDefaultReturnType([]);
          }
          if (response.materialContract.length) {
            handleFields("description", response.materialName);
            setMaterialContract({
              sapId: response?.materialContract[0]?.sapId || "",
              contractId: response?.materialContract[0]?.contractId || "",
              contractName: response?.materialContract[0]?.contractName || "",
            });
            setIsFieldDisabled(false);
            setIsMMIDValidated(true);
            setMId(response.id);
          } else {
            notify("Invalid MMID", "error", false);
          }
        } else {
          notify("Invalid MMID", "error", false);
        }
      } else {
        notify("We are currently experiencing issues, please try again", "error", false);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Spacer top="10px" />
      <Modal
        hideCloseButton={false}
        opened={addReturnHardwareModal}
        disableOutsideClick
        ariaLabel="Individual Return Hardware"
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div css={{ paddingLeft: "1.5rem" }}>
              <Title size="medium">Add Hardware</Title>
            </div>
            <div onClick={() => setAddReturnHardwareModal(false)} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
              <Icon name="close" aria-label="close" alt="close" size="medium" color="#000000" style={{ cursor: "pointer", outline: "none" }} />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
          {isTypeAndMMIDValidated ? (
            <CustomNotification key={generate()} type={"error"} title={"Same Type and MMID already added."} fullBleed={true} ref={clickRef} />
          ) : (
            notification
          )}

          <Loader active={isLoading} />
          <Spacer top="1rem" />
          <FlexRow>
            <CustomInput
              formWidth={"50%"}
              type="text"
              label="MMID"
              value={materialId.value}
              error={materialId.error}
              errorText={materialId.errorText}
              success={materialId.success}
              onChange={(e) => handleFields("MMID", e.target.value)}
              paddingRight="5px"
              maxLength={10}
            />

            <CustomSLButton css={{ margin: "20px 20px 10px 0px" }} primary disabled={isSearchDisabled} onClick={handleMMIDSearch}>
              Search
            </CustomSLButton>
          </FlexRow>
          <FlexRow>
            <CustomInput
              width="100%"
              type="text"
              label="Item Description"
              value={description.value}
              error={description.error}
              errorText={description.errorText}
              success={description.success}
              onChange={(e) => handleFields("description", e.target.value)}
              paddingRight="5px"
              disabled={true}
            />
            <CustomDropdown
              width="100%"
              label="Return Reason"
              error={returnReason.error}
              errorText={returnReason.errorText}
              success={returnReason.success}
              value={returnReason.reason || "Please select"}
              onChange={(e) => handleFields("Return Reason", e.target.value)}
              disabled={isFieldDisabled}
              paddingRight="5px"
              marginBottom="0.4rem"
            >
              <>
                <option disabled>Please select</option>
                {returnReasonList.map((reason) => (
                  <option key={reason.reason} value={reason.reason}>
                    {reason.reason}
                  </option>
                ))}
              </>
            </CustomDropdown>
            <CustomInput
              formWidth="50%"
              type="number"
              label="Quantity"
              value={quantity.value}
              error={quantity.error}
              errorText={quantity.errorText}
              success={quantity.success}
              disabled={isFieldDisabled}
              paddingRight="5px"
              onChange={(e) => handleFields("Quantity", e.target.value)}
            />
            <CustomDropdown
              width="50%"
              type="text"
              label="Type"
              value={type.value ? type.value : "Please select"}
              error={type.error}
              errorText={type.errorText}
              success={type.success}
              disabled={isFieldDisabled}
              onChange={(e) => handleFields("type", e.target.value)}
              paddingRight="5px"
            >
              <>
                {defaultReturnType.length ? (
                  <option
                    key={defaultReturnType[0]?.code}
                    value={defaultReturnType[0]?.code}
                  >{`${defaultReturnType[0]?.code} - ${defaultReturnType[0]?.description}`}</option>
                ) : (
                  <option disabled>Please select</option>
                )}
                {INDIVIDUAL_RETURN_TYPE_LIST?.filter((prosType) => prosType.code !== defaultReturnType[0]?.code).map((type) => (
                  <option key={generate()} value={type.code}>{`${type.code} - ${type.description}`}</option>
                ))}
              </>
            </CustomDropdown>
            {isFieldDisabled ? (
              <p style={{ color: "lightgrey" }}>Please separate multiple ESNs with commas (e.g., 1234567890, 0987654321, 1122334455).</p>
            ) : (
              <p style={{ color: "black" }}>Please separate multiple ESNs with commas (e.g., 1234567890, 0987654321, 1122334455).</p>
            )}
            <CustomInput
              formWidth="100%"
              type="text"
              label="ESN(s)"
              value={esns.value}
              error={esns.error}
              errorText={esns.errorText}
              success={esns.success}
              disabled={isFieldDisabled}
              paddingRight="5px"
              onChange={(e) => handleFields("ESNs", e.target.value)}
            />
          </FlexRow>
          <Spacer bottom="10px" />
          <FlexRow>
            <CustomSLButton css={{ padding: "0 10px 0 10px" }} aria-label="Save" primary disabled={isSaveBtnDisabled} onClick={handleSave}>
              Save
            </CustomSLButton>
            <CustomSLButton aria-label="Cancel" secondary onClick={() => setAddReturnHardwareModal(false)}>
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalBody>
      </Modal>
    </>
  );
};
