import styled from "@emotion/styled";
import { Button } from "@vds/buttons";
import { Col } from "@vds/grids";
import isEmpty from "lodash/isEmpty";
import { lazy, useContext, useEffect, useState } from "react";
import { Header, SLInput } from "../../components/core/Controls";
import { SLGridLayout, SLNotification, SLRowLayout, Spacer } from "../../components/core/Utility";
import ViewUnitInfo from "../../components/features/CreateVehiclesHelperComponents/SearchRevealUnitsTitle/ViewUnitInfo";
import { ACCOUNT_REGEX, TECHNICALID_REGEX, WORK_TICKET_REGEX } from "../../constants/ValidationConstants";
import { CreateVehicleContext } from "../../context/createVehicleContext";
import { useToCreateVehicles } from "../../services/searchVtuUnit/useToCreateVehicles";
import { css } from "@emotion/react";

const ContentWrapper = styled.div`
  padding: 10px 0px;
  margin-left: 10px;
`;
export const CustomRow = styled(SLRowLayout)`
  margin: 0;
  max-width: 1000px;
  & .pl-0 {
    padding-left: 0px;
  }
`;
export const CustomInfoRow = styled(SLRowLayout)`
  margin: 0;
  max-width: 1000px;
  margin-top: 20px;
  & .pl-0 {
    padding-left: 0px;
  }
`;
export const StyledButton = css`
  &button ~ &span[class^="StyledChildWrapper"] {
    padding-left: 5px;
    padding-right: 5px;
  }
`;
export default function ViewUnit() {
  const { revealVehicle, vehicle, esn } = useContext(CreateVehicleContext);
  const { setRevealVehicle: setRevealVehicleValue } = revealVehicle;
  const { vehicle: vehicleValue } = vehicle;
  const { setEsn: setEsnValue } = esn;
  const [technicalIDValue, setTechnicalIDValue] = useState(vehicleValue?.technicalID || "");
  const [accountUIDValue, setAccountUIDValue] = useState(vehicleValue?.accountNumber || "");
  const [workTicketValue, setWorkTicketValue] = useState(vehicleValue?.workTicketNumber || "");
  const [vehicleTrackingId, setVehicleTrackingId] = useState("");
  const [msgValue, setMsgValue] = useState("");
  const [msgTypeValue, setMsgTypeValue] = useState("");
  const [errTechnicalIDValue, setErrTechnicalIDValue] = useState("");
  const [errAccountUIDValue, setErrAccountUIDValue] = useState("");
  const [errWorkTicketValue, setErrWorkTicketValue] = useState("");
  const { vehicleData, isVehicleSubmitLoading, isError, setPayload, setSubmit } = useToCreateVehicles();
  const showErrorNotification = (msg) => {
    setMsgTypeValue("error");
    setMsgValue(msg);
  };

  const showSuccessNotification = (msg) => {
    setMsgTypeValue("success");
    setMsgValue(msg);
  };

  const validateInput = () => {
    const technicalMessage = !technicalIDValue.match(TECHNICALID_REGEX) ? "Provide a valid Technical ID" : "";
    setErrTechnicalIDValue(technicalMessage);
    const accountMessage = !accountUIDValue.match(ACCOUNT_REGEX) ? "Provide a valid AccountUID" : "";
    setErrAccountUIDValue(accountMessage);
    const workTicketMessage = !workTicketValue.match(WORK_TICKET_REGEX) ? "Provide a valid Work Ticket" : "";
    setErrWorkTicketValue(workTicketMessage);

    if (!technicalIDValue.match(TECHNICALID_REGEX) || !accountUIDValue.match(ACCOUNT_REGEX) || !workTicketValue.match(WORK_TICKET_REGEX)) {
      let errMsg = "Provide a ";
      if (!technicalIDValue.match(TECHNICALID_REGEX)) {
        errMsg += "valid Technical ID, ";
      }
      if (!accountUIDValue.match(ACCOUNT_REGEX)) {
        errMsg += "valid AccountUID, ";
      }
      if (!workTicketValue.match(WORK_TICKET_REGEX)) {
        errMsg += "valid Work Ticket, ";
      }
      showErrorNotification(errMsg.substring(0, errMsg.length - 2));
      return true;
    } else {
      showErrorNotification("");
    }
    return false;
  };

  const handleRevealCreateVehicles = () => {
    let validationStatus = validateInput();
    if (!validationStatus) {
      createVehicles();
    }
  };

  const createVehicles = () => {
    setPayload({
      esn: vehicleValue.esn,
      accountNumber: accountUIDValue,
      workTicketNumber: workTicketValue,
      vehicleTrackingId: 0,
      technicalID: technicalIDValue,
    });
    setSubmit(true);
  };

  useEffect(() => {
    if (!isVehicleSubmitLoading) {
      setSubmit(false);
      if (!isEmpty(isError)) {
        showErrorNotification(isError ?? "Error creating reveal vehicle");
      } else if (!isEmpty(vehicleData)) {
        setVehicleTrackingId(vehicleData.vehicleTrackingId);
        showSuccessNotification("Successfully created unit in reveal");
      }
    }
  }, [vehicleData, isVehicleSubmitLoading, isError, setSubmit]);

  return (
    <>
      <Header
        style={{
          backgroundColor: "#ed7000",
          borderRadius: "4px 4px 0 0",
          paddingLeft: "10px",
          marginBottom: "0px",
        }}
      >
        View Unit
      </Header>
      <ContentWrapper>
        <div
          style={{
            width: "100%",
            marginLeft: "-30px",
          }}
        >
          {msgValue !== "" ? <SLNotification type={msgTypeValue} title subtitle={msgValue} fullBleed={false} inlineTreatment={true} /> : ""}
        </div>
        <SLGridLayout>
          <Spacer top="1vw" />
          <CustomRow>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
              <SLInput
                // css={{ paddingBottom: "20px" }}
                value={technicalIDValue}
                data-testid="input-tech-id"
                onChange={(e) => setTechnicalIDValue(e.target.value)}
                error={errTechnicalIDValue !== ""}
                errorText={errTechnicalIDValue}
                type="text"
                label="Technical ID"
                width="100%"
              />
            </Col>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
              <SLInput
                // css={{ paddingBottom: "20px" }}
                value={accountUIDValue}
                data-testid="input-account-id"
                onChange={(e) => setAccountUIDValue(e.target.value)}
                error={errAccountUIDValue !== ""}
                errorText={errAccountUIDValue}
                type="text"
                label="AccountUID"
                width="100%"
              />
            </Col>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
              <SLInput
                // css={{ paddingBottom: "20px" }}
                value={workTicketValue}
                data-testid="input-work-ticket"
                onChange={(e) => setWorkTicketValue(e.target.value)}
                error={errWorkTicketValue !== ""}
                errorText={errWorkTicketValue}
                type="text"
                label="Work Ticket"
                width="100%"
              />
            </Col>
          </CustomRow>
          <CustomRow>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 4, desktop: 4 }}>
              <Button css={StyledButton} size="large" onClick={handleRevealCreateVehicles}>
                Submit
              </Button>
            </Col>
          </CustomRow>

          <CustomInfoRow>
            <Col className="pl-0" colSizes={{ mobile: 4, tablet: 6, desktop: 4 }}>
              <ViewUnitInfo esn={vehicleValue.esn} vehicleTrackingId={vehicleTrackingId} />
            </Col>
          </CustomInfoRow>
        </SLGridLayout>
      </ContentWrapper>
    </>
  );
}

export const ViewUnitMetadata = {
  name: "View Unit",
  id: "view_unit",
  component: lazy(() => import("./ViewUnit")),
  route: "/view_unit",
};
