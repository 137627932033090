import * as urls from "../../api/Camera";

import { getProperty } from "../../utility/getProperty";
import { getRequest, postRequest } from "../RestServices";

export const getVTUDetailsByEsnForCamera = async (esn) => {
  try {
    const _response = await getRequest(urls.SEARCH_VTU_DETAILS + `/${esn}`);
    const _vtu = getProperty(_response, "data", {});
    return { vtuData: _vtu };
  } catch (err) {
    const _vtuErr = getProperty(err.response, "data", {});
    return { vtuDataErr: _vtuErr };
  }
};

export const getOEMDetailsByEsnForCamera = async (esn) => {
  try {
    const _response = await getRequest(urls.SEARCH_OEM_DETAILS + `/${esn}`);
    const _vtu = getProperty(_response, "data", {});
    return { vtuData: _vtu };
  } catch (err) {
    const _vtuErr = getProperty(err.response, "data", {});
    return { vtuDataErr: _vtuErr };
  }
};

export const getAvailableCameras = async (payload) => {
  try {
    return await postRequest(urls.GET_AVAILABLE_CAMERAS, payload);
  } catch (err) {
    return err;
  }
};

export const getAssignedCameras = async (payload) => {
  try {
    const _response = await getRequest(urls.GET_ASSIGNED_CAMERAS + `${payload}`);
    const _assignCams = getProperty(_response, "data", {});
    return { assignCamData: _assignCams };
  } catch (err) {
    const _assignCamErr = getProperty(err.response, "data", {});
    return { assignCamDataErr: _assignCamErr };
  }
};

export const assignCamera = async (payload) => {
  try {
    return await postRequest(urls.ASSIGN_CAMERAS, payload);
  } catch (err) {
    return err;
  }
};

export const getListOfCamerasToUnassign = async (payload) => {
  try {
    return await postRequest(urls.GET_CAMERAS_TO_UNASSIGN, payload);
  } catch (err) {
    return err;
  }
};
export const unassignCamera = async (payload) => {
  try {
    return await postRequest(urls.UNASSIGN_CAMERA, payload);
  } catch (err) {
    return err;
  }
};

export const checkNewEsn = async (payload) => {
  try {
    return await getRequest(`${urls.CHECK_CAMERA_ACTIVATION_STATUS}${payload.newEsn}`);
  } catch (err) {
    return err;
  }
};

export const searchWithOldEsn = async (payload) => {
  try {
    return await getRequest(`${urls.GET_CAMERA_BY_ESN_FOR_SWAP}${payload.oldEsn}`);
  } catch (err) {
    return err;
  }
};

export const searchWithAccountNumber = async (payload) => {
  try {
    return await getRequest(`${urls.GET_CAMERA_BY_ACCOUNT_FOR_SWAP}${payload.accountNumber}/cameras`);
  } catch (err) {
    return err;
  }
};

export const searchControlUnit = async (payload) => {
  try {
    return await postRequest(urls.SEARCH_CONTROL_UNIT, payload);
  } catch (err) {
    return err;
  }
};

export const searchCameraForSwap = async (payload) => {
  try {
    return await postRequest(urls.GET_CAMERA_FOR_SWAP_V2, payload);
  } catch (err) {
    return err;
  }
};

export const swapCameras = async (payload) => {
  try {
    return await postRequest(urls.SWAP_CAMERA, payload);
  } catch (err) {
    return err;
  }
};

export const swapControlUnit = async (payload) => {
  try {
    return await postRequest(urls.SWAP_CONTROL_UNIT, payload);
  } catch (err) {
    return err;
  }
};

export const swapCamerasV2 = async (payload) => {
  try {
    return await postRequest(urls.SWAP_CAMERA_V2, payload);
  } catch (err) {
    return err;
  }
};

export const getCamerasForCreate = async (payload) => {
  try {
    return await postRequest(urls.GET_CREATE_CAMERAS, payload);
  } catch (err) {
    return err;
  }
};

export const createCamerasV2 = async (payload) => {
  try {
    return await postRequest(urls.CREATE_CAMERA + payload?.cameraEsn + "?technicalId=" + payload?.technicalId, null);
  } catch (err) {
    return err;
  }
};

export const getWorkTicketDetails = async (payload) => {
  try {
    const _response = await getRequest(`${urls.GET_WORK_TICKET_DETAILS}${payload}`);
    const _wkt = getProperty(_response, "data", {});
    return { wktData: _wkt };
  } catch (err) {
    const _wktErr = getProperty(err.response, "data", {});
    return { wktDataErr: _wktErr };
  }
};

export const getCamerasForDecommission = async (payload) => {
  try {
    return await postRequest(urls.GET_DECOMM_CAMERAS, payload);
  } catch (err) {
    return err;
  }
};

export const assignControlUnit = async (payload) => {
  try {
    return await postRequest(urls.ASSIGN_CONTROL_UNIT, payload);
  } catch (err) {
    return err;
  }
};

export const decommCameras = async (payload) => {
  try {
    return await postRequest(urls.DECOMM_CAMERAS, payload);
  } catch (err) {
    return err;
  }
};

export const assignStandAloneCamera = async (payload) => {
  try {
    return await postRequest(urls.ASSIGN_STANDALONE_CAMERA, payload);
  } catch (err) {
    return err;
  }
};

export const assignBundleCameras = async (payload) => {
  try {
    return await postRequest(urls.ASSIGN_BUNDLE_CAMERAS, payload);
  } catch (err) {
    return err;
  }
};

export const getControlUnitsForVTU = async (payload) => {
  try {
    return await postRequest(urls.GET_CONTROL_UNITS_FOR_VTU, payload);
  } catch (err) {
    return err;
  }
};

export const getStandAloneCamerasForControlUnit = async (payload) => {
  try {
    return await postRequest(urls.GET_STANDALONE_CAMERAS_FOR_CONTROL_UNIT, payload);
  } catch (err) {
    return err;
  }
};


export const getCameraFeatureRelatedWorktickets = async (payload) => {
  try {
    const _response = await getRequest(`${urls.GET_CAMERA_FEATURE_RELATED_WORKTICKETS}${payload}`);
    const _wkt = getProperty(_response, "data", {});
    return { wktData: _wkt };
  } catch (err) {
    const _wktErr = getProperty(err.response, "data", {});
    return { wktDataErr: _wktErr };
  }
};