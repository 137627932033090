import { Button } from "@vds/buttons";
import React, { useState, lazy, useEffect } from "react";
import { FlexRow, Header, SLNotification, SmallText } from "../../components/core/utility";
import styled from "styled-components";
import searchIcon from "../../assets/icons/search_blk.png";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { VENDORS_LIST_COL, VENDORS_MMID_LIST_COL } from "./VendorInformationConstants";

import { InstallersMetadata } from "./Installers";

import { Parser } from "json2csv";
import moment from "moment";
import _ from "lodash";
import { AddVendorModal } from "./AddVendorModal";

import { getVendorsList } from "../../services/scheduler/getVendorsList";
import { generate } from "shortid";
import { Loader } from "@vds/loaders";
import { getInstallers } from "../../services/scheduler/getInstallers";
import { installersMapping } from "../../services/scheduler/installersMapping";
import { getSyncToMapData } from "../C4S Decommission - Replacement/utils";
import useOutsideClick from "../../customHook/use-outside-click";
import { Modal, ModalBody } from "@vds/modals";
import { Title } from "@vds/typography";
import Icon from "@vds/icons";
import { css } from "@emotion/react";
import { VendorMMIDGridComponent } from "./VendorMMIDGridComponent";
import { getVendorMaterials } from "../../services/scheduler/getVendorMaterials";
import { updateVendorMaterials } from "../../services/scheduler/updateVendorMaterials";

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid black;
  width: 220px;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

const CustomButton = styled(Button)`
  >span: nth-child(2n) {
    padding: 0.6875rem 1.75rem !important;
  }
`;

const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  width: auto !important;
  @media (max-width: 767px) {
    width: auto% !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }
  .ModalDialogWrapper-sc-ov12ij-0 > span.hULveg {
    padding-right: 0px;
  }

  & footer {
    padding: 0;
  }
`;

export default function Vendors(props) {
  const [globalSearchValue, setGlobalSearchValue] = useState("");
  const [isLoading, setLoader] = useState(false);
  const [vendorsList, setVendorsList] = useState([]);
  const [notification, setNotification] = useState(null);
  const [isVendorModalOpened, setIsVendorModalOpened] = useState(false);
  const [isConfigureMMIDModalOpened, setIsConfigureMMIDModalOpened] = useState(false);
  const [editVendorRecord, setEditVendorRecord] = useState({});
  const [vendorIdList, setVendorIdList] = useState(new Set());
  const [mmIdsList, setMMIDsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [updatedMMIDs, setUpdatedMMIDs] = useState([]);
  const handleClickOutside = () => {
    setNotification(null);
  };
  const clickRef = useOutsideClick(handleClickOutside);

  const notify = (content, type, isNotificationWithTimer = false) => {
    setNotification(
      <>
        <SLNotification key={generate()} type={type} title={content} fullBleed={false} ref={clickRef} />
      </>
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getVendors = async () => {
    setLoader(true);
    let response = await getVendorsList();
    let vIdList = new Set();
    if (response.status === 200 && response?.data?.data) {
      let vendorsList = response?.data?.data?.map((vendor) => {
        vIdList.add(vendor.vendorId);
        return {
          id: vendor.id,
          vendorId: vendor.vendorId,
          vendorName: vendor.vendorName,
          installer: "View Installer",
          salesOrg: vendor.salesOrgIds,
          active: vendor.isActive ? "Yes" : "No",
          contactName: vendor.contactName,
          contactPhone: vendor.contactPhone,
          contactEmail: vendor.contactEmail,
          syncToMap: "Sync to Map",
          address1: vendor.vendorAddress1,
          address2: vendor.vendorAddress2,
          city: vendor.vendorCity,
          state: vendor.vendorState,
          country: vendor.vendorCountry,
          zip: vendor.vendorZipcode,
          configureMMIDs: "Configure MMIDs",
        };
      });
      setVendorIdList(vIdList);
      setVendorsList(vendorsList);
    } else {
      notify("Unable to fetch the data", "error");
    }
    setLoader(false);
  };

  const onHyperLinkClick = (data, column) => {
    if (column === "Installer") {
      props.pageChangeCallback(InstallersMetadata.id, data);
    }
    if (column === "Vendor ID") {
      setEditVendorRecord(data);
      setIsVendorModalOpened(true);
    }
    if (column === "Sync To Map") {
      getInstallersList({ id: data?.id, vendorName: data?.vendorName, salesOrg: data?.salesOrg, active: data?.active });
    }
    if (column === "Configure MMIDs") {
      setSelectedVendor({ vendorId: data?.vendorId, contactName: data?.contactName });
      getMaterial(data?.id);
    }
  };

  const getMaterial = async (vendorId) => {
    setLoader(true);
    let response = await getVendorMaterials(vendorId);
    if (response.status === 200 && response?.data?.data) {
      setMMIDsList(response?.data?.data);
      setLoader(false);
    } else {
      setMMIDsList([]);
      setLoader(false);
      notify("Unable to fetch the data", "error");
    }
    setIsConfigureMMIDModalOpened(true);
  };

  const closeHandler = () => {
    setSelectedVendor(null);
    setIsConfigureMMIDModalOpened(false);
    setMMIDsList([]);
    setUpdatedMMIDs([]);
  };

  const getInstallersList = async (vendorDetails) => {
    setLoader(true);
    let response = await getInstallers(vendorDetails?.id);
    if (response.status === 200 && response?.data?.data) {
      let installerList = response?.data?.data;
      if (installerList?.length) {
        handleSyncToMap(vendorDetails, installerList);
      } else {
        notify("No Installers Available.", "error");
        setLoader(false);
      }
    } else {
      notify("Unable to fetch the Installers", "error");
      setLoader(false);
    }
  };

  const handleSyncToMap = async (vendorDetails, installerList) => {
    let payload = {
      vendorName: vendorDetails?.vendorName,
      vendorStatus: vendorDetails?.active,
      salesOrgs: vendorDetails?.salesOrg,
      installerLocations: getSyncToMapData(installerList),
    };
    let missingFieldsArray = [];
    for (const key of Object.keys(payload?.installerLocations)) {
      if (key === "status" || key === "installerId") {
        if (payload?.installerLocations[key] === "" || payload?.installerLocations[key] === undefined || payload?.installerLocations[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    for (const key of Object.keys(payload)) {
      if (key === "vendorName" || key === "vendorStatus") {
        if (payload[key] === "" || payload[key] === undefined || payload[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      setLoader(false);
      return setTimeout(() => {
        notify(
          "Unable to sync to map due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    }
    let response = await installersMapping(payload);
    if (response?.status === 200 && response?.data?.message?.toLowerCase() === "success") {
      notify("Synced To Map successfully", "success");
    } else if (response?.data?.statusCode === "404") {
      notify(response?.data?.message, "error", false);
    } else {
      notify("We are currently experiencing issues, please try again", "error", false);
    }
    setLoader(false);
  };

  const onGlobalSearchChange = (e) => {
    setGlobalSearchValue(e.target.value);
  };

  const exportCSV = () => {
    const filename = "Vendors" + "_" + moment().format("MM-DD-YYYY");
    let columnsToExclude = ["installer", "syncToMap"];
    let dataToExport = vendorsList;
    let fieldsToExclude = VENDORS_LIST_COL.map((col) => {
      if (col.containsImage) {
        return col.value;
      }
    });
    fieldsToExclude = [...fieldsToExclude, ...columnsToExclude];
    let fieldsToExport = _.cloneDeep(dataToExport).map((data) => _.omit(data, fieldsToExclude));
    const exportColumnsForCSV = VENDORS_LIST_COL.filter((col) => !col.containsImage && !columnsToExclude.includes(col.value)).map((col) => {
      return { label: col.key, value: col.value };
    });
    const json2csvParser = new Parser({ fields: exportColumnsForCSV });
    const csv = json2csvParser.parse(fieldsToExport);
    saveFile(csv, filename ?? "data", "text/csv", ".csv");
  };

  const saveFile = (buffer, fileName, type, extension) => {
    import("file-saver").then((module) => {
      if (module?.default) {
        const data = new Blob([buffer], {
          type: type,
        });

        module.default.saveAs(data, fileName + extension);
      }
    });
  };

  const saveConfigureMMID = async () => {
    setIsConfigureMMIDModalOpened(false);
    if (updatedMMIDs.length === 0) {
      notify("No changes to save", "error", true);
      return;
    } else {
      let payload = updatedMMIDs.map((data) => {
        return {
          id: data.id,
          vendorId: data.vendorId,
          include: data.include,
          autoScheduler: data.autoScheduler,
        };
      });
      setLoader(true);
      let response = await updateVendorMaterials(payload);
      if (response.status === 200) {
        setLoader(false);
        notify(`MMIDs updated for ${selectedVendor?.contactName}.`, "success", true);
      } else {
        notify("Unable to update MMIDs", "error", true);
        setLoader(false);
      }
    }
    setSelectedVendor(null);
    setUpdatedMMIDs([]);
    setMMIDsList([]);
  };

  return (
    <div style={{ width: "-webkit-fill-available" }}>
      <Loader active={isLoading} fullscreen />
      {notification}
      {isVendorModalOpened && (
        <AddVendorModal
          isVendorModalOpened={isVendorModalOpened}
          setIsVendorModalOpened={setIsVendorModalOpened}
          getVendors={getVendors}
          editVendorRecord={editVendorRecord}
          setEditVendorRecord={setEditVendorRecord}
          vendorIdList={vendorIdList}
        />
      )}
      <div tabIndex={0}>
        <Header>Vendors</Header>
      </div>
      <FlexRow marginTop="30px" justifyContent="space-between">
        <FlexRow>
          <CustomButton style={{ marginRight: "20px" }} size="large" onClick={() => setIsVendorModalOpened(true)}>
            Add New Vendor
          </CustomButton>
          <CustomButton size="large" secondary onClick={exportCSV}>
            Download
          </CustomButton>
        </FlexRow>
        <SearchInputWrapper>
          <SearchInput
            role="search"
            aria-label={globalSearchValue}
            value={globalSearchValue}
            type="text"
            onChange={onGlobalSearchChange}
            placeholder="Search"
          ></SearchInput>
          <img aria-label="search" role="img" src={searchIcon} height="20px" width="20px"></img>
        </SearchInputWrapper>
      </FlexRow>
      <br />
      <div tabIndex={0} aria-label="table">
        <GridComponent
          rows={vendorsList}
          columns={VENDORS_LIST_COL}
          paginator={true}
          hideGlobalFilter={true}
          hyperLinkClicked={onHyperLinkClick}
          searchKeyboardValue={globalSearchValue}
          whiteSpace="unset"
          itemsPerPageDropDownValues={[
            { label: 20, value: 20 },
            { label: 30, value: 30 },
            { label: 40, value: 40 },
            { label: 50, value: 50 },
            { label: "All", value: vendorsList.length },
          ]}
        />
      </div>
      {isConfigureMMIDModalOpened && (
        <div>
          <Modal
            role="dialog"
            ariaLabel="Configure MMIDs"
            hideCloseButton={false}
            opened={true}
            disableOutsideClick
            css={StyledModal}
            closeButton={
              <FlexRow justifyContent="space-between" style={{ marginLeft: "1.3rem", marginTop: "1rem" }} onClick={(event) => event.preventDefault()}>
                <Title>Configure MMIDs - {selectedVendor?.contactName}</Title>
                <div onClick={closeHandler} style={{ marginRight: "1rem" }}>
                  <Icon
                    aria-label="close"
                    alt="close-icon"
                    role="img"
                    name="close"
                    size="medium"
                    color="#000000"
                    style={{ cursor: "pointer", outline: "none" }}
                  />
                </div>
              </FlexRow>
            }
          >
            <ModalBody style={{ height: "400px" }}>
              <VendorMMIDGridComponent
                rows={mmIdsList}
                columns={VENDORS_MMID_LIST_COL}
                onSaveClick={saveConfigureMMID}
                updatedMMIDs={updatedMMIDs}
                setUpdatedMMIDs={setUpdatedMMIDs}
                whiteSpace="unset"
              />
            </ModalBody>
          </Modal>
        </div>
      )}
    </div>
  );
}

export const VendorsMetadata = {
  name: "Vendors Table",
  id: "Vendors_Table",
  component: lazy(() => import("./Vendors")),
  route: "/vendors",
};
