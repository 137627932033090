import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const OPPORTUNITY_HISTORY_COL = [
  {
    key: "Opportunity ID",
    value: "salesForceOpportunityID",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Account ID",
    value: "accountID",
    filterType: "Status",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Customer Name",
    value: "salesForceAccountName",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Account Number",
    value: "salesForceAccountNo",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
  },
  {
    key: "Opportunity Status",
    value: "oppStatus",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.lsmall,
    containsImage: true,
  },
  {
    key: "ERP BP Number",
    value: "bpNumber",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "ERP PO Number",
    value: "erpPoNumber",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Opportunity Processed Date",
    value: "creationTime",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.xmedium,
    isDate: true,
  },
  {
    key: "SL Opportunity Id",
    value: "superLibraOpportunityID",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Opportunity Closed Date",
    value: "salesConfirmedDate",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
    isDate: true,
  },
  {
    key: "Type",
    value: "type",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Sub Type",
    value: "subType",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];
