import { COLUMN_WIDTHS, EDIT_FIELD_TYPES } from "../../../constants/ApplicationConstants";

export const availableCameraColumnsForAssign = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: true,
  },
  {
    key: "DRID",
    value: "drid",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: true,
  },
  {
    key: "ICCID",
    value: "iccid",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: true,
  },
  {
    key: "IMEI",
    value: "imei",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: true,
  },
  {
    key: "Box Type",
    value: "boxType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: true,
  },
  {
    key: "Reveal VTU Id",
    value: "vehicleTrackingId",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: true,
  },
  {
    key: "Status",
    value: "confirmStatus",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
    isDynamicFont: true,
  },
];

export const assignCameraColumns = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "DRID",
    value: "drid",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "ICCID",
    value: "iccid",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Product Type",
    value: "cameraProductType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Product ID",
    value: "sku",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "IMEI",
    value: "imei",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Box Type",
    value: "boxType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Reveal VTU Id",
    value: "vehicleTrackingId",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
];

export const assignCameraColumnsV2 = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "Channel Number",
    value: "channelNumber",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isEditable: true,
    editFieldType: EDIT_FIELD_TYPES.dropDown,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Product Type",
    value: "cameraProductType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isEditable: true,
    editFieldType: EDIT_FIELD_TYPES.dropDown,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Product ID",
    value: "sku",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "IMEI",
    value: "imei",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "Box Type",
    value: "boxType",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "Reveal VTU Id",
    value: "vehicleTrackingId",
    width: COLUMN_WIDTHS.lsmall,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
];

export const workTicketColumns = [
  {
    key: "Work Ticket",
    value: "workTicketNumber",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Order Type",
    value: "orderDetailType",
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Tech Ref ID",
    value: "techRefId",
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Line Item",
    value: "lineItemDescription",
    width: COLUMN_WIDTHS.large,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Vendor",
    value: "vendorID",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Installation Address",
    value: "installationAddress",
    width: COLUMN_WIDTHS.large,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Ticket Type",
    value: "workTicketType",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "CRM Reference ID",
    value: "crmReferenceId",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
  {
    key: "Status",
    value: "status",
    width: COLUMN_WIDTHS.small,
    isSortable: true,
    isFilterEnabled: true,
  },
];

export const lineItemColumns = [
  {
    key: "Line Item",
    value: "lineItem",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Number",
    value: "number",
    width: COLUMN_WIDTHS.small,
  },
];

export const cameraHistoryColumns = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "DRID",
    value: "drid",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "ICCID",
    value: "iccid",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "IMEI",
    value: "imei",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Box Type",
    value: "boxType",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Reveal VTU Id",
    value: "vehicleTrackingId",
    width: COLUMN_WIDTHS.small,
  },
];

export const swapControlUnitColumns = [
  {
    key: "ESN",
    value: "esn",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "Channel Number",
    value: "channelNumber",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isEditable: true,
    editFieldType: EDIT_FIELD_TYPES.dropDown,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Product Type",
    value: "cameraProductType",
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
    isEditable: true,
    editFieldType: EDIT_FIELD_TYPES.dropDown,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Product ID",
    value: "sku",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "IMEI",
    value: "imei",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "Box Type",
    value: "boxType",
    width: COLUMN_WIDTHS.small,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
  {
    key: "Reveal VTU Id",
    value: "vehicleTrackingId",
    width: COLUMN_WIDTHS.lsmall,
    isFilterEnabled: false,
    filterType: "String",
    isCustomFilter: false,
    isSortable: false,
  },
];
