import styled from "@emotion/styled";
import { Button } from "@vds/buttons";
import { Col, Grid, Row } from "@vds/grids";
import { Loader } from "@vds/loaders";
import { Body } from "@vds/typography/dist/cjs/components/Body";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { generate } from "shortid";
import InProgress from "../../assets/icons/Process.svg";
import Completed from "../../assets/icons/Success.svg";
import Failed from "../../assets/icons/failed.svg";
import { SLCheckBox, SLInput } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { FlexRow, Spacer, SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/Utility";
import { ProgressFlow } from "../../components/features/ProgressFlow";
import { OPPORTUNITY_ID } from "../../constants/ValidationConstants";
import { PrivilegeContext } from "../../context/privilegeContext";
import { fetchProductInfo, opportunityHistory } from "../../services/reports/reportsOpportunityHistory";
import { openTab } from "../../utility/navigation";
import { fetchGridComponentState, saveGridComponentState } from "../../utility/persistGridComponentState";
import { REPORTS_MILESTONE_METADATA } from "../pageList";
import { REPORTS_OPPORTUNITY_HISTORY_COL } from "./reportsHistoryColumns";
import { INFO_MODAL_COLUMNS } from "./infoModalColumns";
import { css } from "@emotion/react";
const LocationStyle = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`;

const CustomImage = styled.div`
  display: flex;
`;

const Box = styled.div`
  box-sizing: border-box;
  min-height: 205px;
  left: 163px;
  top: 168px;
  background: #fbfbfb;
  border: 1px solid #d8dada;
  border-radius: 5px;
  padding-bottom: 10px;
`;

export const StyledCol1 = styled(Col)`
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
`;
export const CustomRow = styled(SLRowLayout)`
  margin: 0;
  max-width: 1000px;
  & .pl-0 {
    padding-left: 0px;
  }
`;
export const CustomCheckBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  padding-left: 0px;
  margin: 0;
  max-width: 1000px;
`;
export const StyledSLCheckBox = styled(SLCheckBox)`
  margin-bottom: 10px;
`;
export const StyledButton = css`
  width: 100%;
  &button ~ &span[class^="StyledChildWrapper"] {
    padding-left: 5px;
    padding-right: 5px;
  }
`;
const GridStyle = styled.div`
  .datatable-style .mark-red {
    background-color: rgba(247, 65, 65, 0.15) !important;
  }
`;
const rowClass = (data) => {
  return {
    "mark-red": data.stage?.endsWith("Failed"),
  };
};
const Status = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PROGRESS: "PROGRESS",
  UNDEFINED: "UNDEFINED",
};

const progressData = [
  {
    title: "Opportunity Created",
  },
  {
    title: "Account Setup",
  },
  {
    title: "Document Signed",
  },
  {
    title: "Sale Confirmed",
  },

  {
    title: "Order Created",
    positionStrategy: "fixed",
    offset: 40,
  },
  {
    title: "Reveal Account Created",
  },

  {
    title: "Order Fulfillment",
    positionStrategy: "fixed",
    offset: 40,
  },
  {
    title: "Order Completed",
  },
];
const States = {
  failed: "failed",
  completed: "success",
  progress: "progress",
};
const mapState = (data, state) => {
  switch (state) {
    case States.completed:
      data.status = Status.SUCCESS;
      break;
    case States.failed:
      data.status = Status.FAILED;
      break;
    case States.progress:
      data.status = Status.PROGRESS;
      break;
  }
};
const HoverComponent = ({ totalQuantity, shippedQuantity, showShipped }) => {
  return (
    <>
      <FlexRow justifyContent="space-between" css={{ borderBottom: showShipped ? "1px solid gray" : null, padding: "20px" }}>
        <Body bold large>
          Total Quantity{" "}
        </Body>
        <Body small> {totalQuantity} </Body>
      </FlexRow>
      {showShipped && (
        <FlexRow justifyContent="space-between" css={{ padding: "20px" }}>
          <Body bold large>
            Shipped Quantity{" "}
          </Body>
          <Body small> {shippedQuantity} </Body>
        </FlexRow>
      )}
    </>
  );
};
const RowTemplate = ({ data }) => {
  const [milestoneData, setMilestoneData] = useState(progressData);
  const [isLoading, setIsLoading] = useState(false);
  const [productInfo, setProductInfo] = useState([]);
  const fetchData = async () => {
    setIsLoading(true);
    const info = await fetchProductInfo(data?.salesForceOpportunityId);
    if (info.status === 200) {
      setProductInfo(info.data.data);
    }
    setIsLoading(false);
  };

  const preprocessData = useCallback((data) => {
    // const states = data.states;
    const updatedData = cloneDeep(progressData);
    // const errorMessage = data?.opportunityInfo?.errorMessage;
    switch (data?.stage) {
      case "M1InProgress":
        updatedData[0].state = States.completed;
        // updatedData[1].state = States.completed;
        updatedData[1].state = States.progress;
        break;
      case "M1Completed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        // updatedData[2].state = States.completed;
        break;
      case "M1Failed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.failed;
        // updatedData[2].state = States.failed;
        // updateErrorInTitle(updatedData[2], errorMessage);
        break;
      case "M2InProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.progress;
        // updatedData[5].state = States.progress;
        break;
      case "M2Completed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        // updatedData[5].state = States.completed;
        break;
      case "M2Failed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.failed;
        // updatedData[5].state = States.failed;
        // updateErrorInTitle(updatedData[5], errorMessage);

        break;
      case "OrderCreationInProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.progress;
        // updatedData[5].state = States.completed;
        // updatedData[6].state = States.progress;
        break;
      case "OrderCreationCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        // updatedData[5].state = States.completed;
        // updatedData[6].state = States.completed;
        break;
      case "OrderCreationFailed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.failed;
        // updatedData[5].state = States.completed;
        // updatedData[6].state = States.failed;
        // updateErrorInTitle(updatedData[6], errorMessage);

        break;
      case "RevealAccountCreationInProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.progress;
        // updatedData[6].state = States.completed;
        // updatedData[7].state = States.progress;
        break;
      case "RevealAccountCreationCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        // updatedData[6].state = States.completed;
        // updatedData[7].state = States.completed;
        break;
      case "RevealAccountCreationFailed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.failed;
        // updatedData[6].state = States.completed;
        // updatedData[7].state = States.failed;
        // updateErrorInTitle(updatedData[7], errorMessage);

        break;
      case "OrderFulfillmentInProgress":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.progress;
        // updatedData[7].state = States.completed;
        // updatedData[8].state = States.progress;
        break;
      case "OrderFulfillmentCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.completed;
        // updatedData[7].state = States.completed;
        // updatedData[8].state = States.completed;
        break;
      case "OrderFulfillmentFailed":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.failed;
        // updatedData[7].state = States.completed;
        // updatedData[8].state = States.failed;
        // updateErrorInTitle(updatedData[8], errorMessage);
        break;
      case "OrderCompleted":
        updatedData[0].state = States.completed;
        updatedData[1].state = States.completed;
        updatedData[2].state = States.completed;
        updatedData[3].state = States.completed;
        updatedData[4].state = States.completed;
        updatedData[5].state = States.completed;
        updatedData[6].state = States.completed;
        updatedData[7].state = States.completed;
        break;
    }

    updatedData.forEach((item) => {
      mapState(item, item.state);
      delete item.state;
    });
    // if (data.totalQuantity && data.shippedQuantity) {
    //   updatedData[8].description = `Shipped qty - ${data.shippedQuantity} Total qty - ${data.totalQuantity}`;
    // }
    updatedData[4].id = `${data?.salesForceOpportunityId}OrderCreated`;
    updatedData[4].HoverComponent = <HoverComponent totalQuantity={data?.totalQuantity ?? 0}></HoverComponent>;
    updatedData[6].id = `${data?.salesForceOpportunityId}OrderFulfillment`;
    updatedData[6].HoverComponent = (
      <HoverComponent totalQuantity={data?.totalQuantity ?? 0} shippedQuantity={data?.shippedQuantity ?? 0} showShipped></HoverComponent>
    );
    setMilestoneData(updatedData);
  }, []);
  useEffect(() => {
    fetchData();
    preprocessData(data);
  }, []);
  return (
    <>
      <FlexRow
        margin="10px"
        padding="50px 50px 50px"
        flexBasis="1171px"
        alignItems="flex-start"
        css={{ background: "#fbfbfb", border: "1px solid #d8dada", borderRadius: "5px" }}
      >
        <ProgressFlow data={milestoneData} />
        <Spacer height="103px" />
        <Loader active={isLoading} />
        <GridComponent
          isSelectionEnabled={false}
          isExportEnabled={false}
          noDataMessage="Data Unavailable"
          rows={productInfo}
          columns={INFO_MODAL_COLUMNS}
          title="Product Info"
          reorderableColumns
        />
      </FlexRow>
    </>
  );
};
const rowExpansionTemplate = (data) => {
  return <RowTemplate data={data} />;
};

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  const [opportunityHistoryArr, setOpportunityHistoryArr] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toISOString().split(".")[0].split("T")[0].concat("T").concat("00:00:00")
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().split(".")[0].split("T")[0].concat("T").concat("23:59:59"));
  const [startDisplayDate, setStartDisplayDate] = useState("");
  const [endDisplayDate, setEndDisplayDate] = useState("");
  const [opportunityId, setOpportunityId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [dateFlag, setDateFlag] = useState(false);
  const [oppFlag, setOppFlag] = useState(false);
  const [accFlag, setAccFlag] = useState(false);
  const [complete, setComplete] = useState(true);
  const [inProgress, setInProgress] = useState(true);
  const [failed, setFailed] = useState(true);
  const [completeArray, setCompleteArray] = useState([]);
  const [oppErr, setOppErr] = useState(false);
  const [accErr, setAccErr] = useState(false);
  const [accSucc, setAccSucc] = useState(false);
  const [accErrText, setAccErrText] = useState("");
  const [oppSucc, setOppSucc] = useState(false);
  const [oppErrText, setOppErrText] = useState("");
  const tableRef = useRef();
  const { userInfo } = useContext(PrivilegeContext);
  const onCustomSaveState = (state) => {
    // state.userId = userInfo.email;
    saveGridComponentState(state, "reports_info");
    // window.localStorage.setItem("dt-state-demo-custom", JSON.stringify(pref));

    // window.localStorage.setItem("dt-state-demo-custom", JSON.stringify(state));
  };

  const onCustomRestoreState = async (state) => {
    return await fetchGridComponentState("reports_info");
    // return JSON.parse(window.localStorage.getItem("dt-state-demo-custom"));
  };

  const formattedRows = (data) => {
    return [...(data || [])].map((d) => {
      d.opportunityCreatedDate = d.opportunityCreatedDate && new Date(d.opportunityCreatedDate);
      d.opportunityCloseDate = d.opportunityCloseDate && new Date(d.opportunityCloseDate);
      d.salesConfirmedDate = d.salesConfirmedDate && new Date(d.salesConfirmedDate);
      d.selfInstall = d.selfInstall === false ? "No" : "Yes";
      return d;
    });
  };

  const getOpportunityHistory = async () => {
    const config = {
      startDate: !dateFlag ? startDate : "",
      endDate: !dateFlag ? endDate : "",
      opportunityID: opportunityId,
      accountID: accountId,
    };
    if (opportunityId === "" && accountId === "" && startDate === "" && endDate === "") {
      notify("Please enter details or select date range to search ", "error");
    } else if ((startDate === "" && endDate !== "") || (startDate !== "" && endDate === "")) {
      notify("Please select both start date and end date to search ", "error");
    } else if (
      (opportunityId !== "" && validateInput("oppId", opportunityId)) ||
      (accountId !== "" && validateInput("accId", accountId)) ||
      (startDate !== "" && endDate !== "")
    ) {
      setLoading(true);
      await opportunityHistory(config).then((response) => {
        if (response?.response?.status === 404) {
          notify("Records Not Found!", "error");
          setOpportunityHistoryArr([]);
        } else if (response.rows) {
          setNotification(null);
          setCompleteArray(response.rows);
          checkBoxFilter(response.rows);
          if (response.rows.length == 0) {
            notify("Opportunities not found!", "error");
          }
        } else {
          notify("Records Not Found!", "error");
        }
      });
      setLoading(false);
    }
  };

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const handleHyperLinkClicked = (data, key) => {
    if (key == "Opportunity Status") {
      // addPage(ReportsMilestoneMetadata.id, { opportunityId: data.salesForceOpportunityId });
      openTab(REPORTS_MILESTONE_METADATA, { opportunityId: data.salesForceOpportunityId });
    }
  };

  const renderImage = (data) => {
    if (data && data === "OrderFulfillmentCompleted") {
      return (
        <>
          <CustomImage>
            <img src={Completed} height="16px" width="16px" css={{ marginRight: "8px" }} />
            <Body primitive="span">{data}</Body>
          </CustomImage>
        </>
      );
    } else if (data && data.endsWith("Failed")) {
      return (
        <>
          <CustomImage>
            <img src={Failed} height="16px" width="16px" css={{ marginRight: "8px" }} />
            <Body primitive="span">{data}</Body>
          </CustomImage>
        </>
      );
    } else {
      return (
        <>
          <CustomImage>
            <img src={InProgress} height="16px" width="16px" css={{ marginRight: "8px" }} />
            <Body primitive="span">{data}</Body>
          </CustomImage>
        </>
      );
    }
  };

  const selectStartDate = (event) => {
    setStartDisplayDate(event);
    var tzoffset = new Date(event).getTimezoneOffset() * 60000;
    var localISOTime = new Date(new Date(event) - tzoffset).toISOString().split(".")[0];
    setStartDate(localISOTime);
  };

  const selectEndDate = (event) => {
    setEndDisplayDate(event);
    var tzoffset = new Date(event).getTimezoneOffset() * 60000;
    var localISOTime = new Date(new Date(event) - tzoffset).toISOString().split(".")[0];
    var dateArr = localISOTime.split("T");
    let str = "23:59:59";
    var finalDate = dateArr[0].concat("T").concat(str);
    setEndDate(finalDate);
  };

  const initializeDate = () => {
    var startdate = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toLocaleString().split(",");
    setStartDisplayDate(startdate[0]);
    var closedate = new Date().toLocaleString().split(",");
    setEndDisplayDate(closedate[0]);
    setEndDate(new Date().toISOString().split(".")[0].split("T")[0].concat("T").concat("23:59:59"));
    setStartDate(new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toISOString().split(".")[0].split("T")[0].concat("T").concat("00:00:00"));
  };
  useEffect(() => {
    initializeDate();
    getOpportunityHistory();
  }, []);

  const validateInput = (text, input) => {
    if (input.match(OPPORTUNITY_ID) && text === "oppId") {
      setOppErr(false);
      setOppErrText(null);
      setOppSucc(true);
      return true;
    } else if (input.match(OPPORTUNITY_ID) && text === "accId") {
      setAccErr(false);
      setAccErrText(null);
      setAccSucc(true);
      return true;
    } else if (text === "oppId") {
      setOppErr(true);
      setOppErrText("Enter a valid Opportunity id");
      return false;
    } else {
      setAccErr(true);
      setAccErrText("Enter a valid Account id");
      return false;
    }
  };

  const opportunityHandler = (event) => {
    setOpportunityId(event.target.value);
    if (event.target.value === "") {
      setDateFlag(false);
      setAccFlag(false);
      setOppSucc(false);
      setOppErr(false);
      setOppErrText(null);
    } else {
      setDateFlag(true);
      setAccFlag(true);
    }
  };

  const accountHandler = (event) => {
    setAccountId(event.target.value);
    if (event.target.value === "") {
      setOppFlag(false);
      setAccErr(false);
      setAccErrText(null);
      setAccSucc(false);
    } else {
      setEndDisplayDate("");
      setEndDate("");
      setStartDate("");
      setStartDisplayDate("");
      setOppFlag(true);
    }
  };

  const completeHandler = (e) => {
    setComplete(e.target.checked);
  };

  const failedHandler = (e) => {
    setFailed(e.target.checked);
  };

  const inprogressHandler = (e) => {
    setInProgress(e.target.checked);
  };

  const resetHandler = () => {
    setAccountId("");
    setOpportunityId("");
    setAccFlag(false);
    setDateFlag(false);
    setOppFlag(false);
    setComplete(true);
    setFailed(true);
    setInProgress(true);
    setAccErr(false);
    setOppErr(false);
    setAccErrText("");
    setOppErrText("");
    setAccSucc(false);
    setOppSucc(false);
    initializeDate();
  };

  useEffect(() => {
    checkBoxFilter(completeArray);
  }, [complete, inProgress, failed]);

  const checkBoxFilter = (totalArray) => {
    if (totalArray) {
      let filteredArray = totalArray;
      let completedOpportunityHistory = [];
      let InProgressOpportunityHistory = [];
      let FailedOpportunityHistory = [];
      if (!complete && !inProgress && !failed) {
        setOpportunityHistoryArr([]);
      } else {
        if (complete) {
          completedOpportunityHistory = totalArray.filter((opportunity) => opportunity.stage === "OrderFulfillmentCompleted");
        }
        if (inProgress) {
          InProgressOpportunityHistory = totalArray.filter(
            (opportunity) => opportunity.stage !== "OrderFulfillmentCompleted" && !opportunity.stage?.endsWith("Failed")
          );
        }
        if (failed) {
          FailedOpportunityHistory = totalArray.filter((opportunity) => opportunity.stage?.endsWith("Failed"));
        }
        filteredArray = [...completedOpportunityHistory, ...InProgressOpportunityHistory, ...FailedOpportunityHistory];
        setOpportunityHistoryArr(formattedRows(filteredArray));
      }
    }
  };

  return (
    <>
      <LocationStyle>
        {notification}
        <Loader active={loading} />
        <Box>
          <SLGridLayout
            colSizes={{
              mobile: 1,
              tablet: 2,
              desktop: 3,
            }}
          >
            <CustomRow>
              <Col
                className="pl-0"
                colSizes={{ mobile: 4, mobileLarge: 6, tablet: 6, tabletLarge: 6, desktop: 6, desktopLarge: 6, desktopXLarge: 6 }}
              >
                <SLInput
                  label="Opportunity Id"
                  value={opportunityId}
                  onChange={opportunityHandler}
                  disabled={oppFlag}
                  errorText={oppErrText}
                  error={oppErr}
                  success={oppSucc}
                  maxLength="18"
                ></SLInput>
              </Col>
              <Col
                className="pl-0"
                colSizes={{ mobile: 4, mobileLarge: 6, tablet: 6, tabletLarge: 6, desktop: 6, desktopLarge: 6, desktopXLarge: 6 }}
              >
                <SLInput
                  label="Account Id"
                  value={accountId}
                  onChange={accountHandler}
                  errorText={accErrText}
                  error={accErr}
                  success={accSucc}
                  disabled={accFlag}
                  maxLength="18"
                ></SLInput>
              </Col>
              <Col
                className="pl-0"
                colSizes={{ mobile: 4, mobileLarge: 6, tablet: 6, tabletLarge: 6, desktop: 6, desktopLarge: 6, desktopXLarge: 6 }}
              >
                <SLInput
                  type="calendar"
                  label="Date Range From"
                  minDate={new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)}
                  maxDate={new Date()}
                  onChange={selectStartDate}
                  format="MM/DD/YYYY"
                  value={startDisplayDate}
                  disabled={dateFlag}
                  errorText={""}
                  error={false}
                ></SLInput>
              </Col>
              <Col
                className="pl-0"
                colSizes={{ mobile: 4, mobileLarge: 6, tablet: 6, tabletLarge: 6, desktop: 6, desktopLarge: 6, desktopXLarge: 6 }}
              >
                <SLInput
                  type="calendar"
                  label="Date Range To"
                  minDate={new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)}
                  maxDate={new Date()}
                  onChange={selectEndDate}
                  value={endDisplayDate}
                  format="MM/DD/YYYY"
                  disabled={dateFlag}
                  errorText={""}
                  error={false}
                ></SLInput>
              </Col>
            </CustomRow>
            <CustomRow>
              <Spacer bottom="5px">
                <Body size="large" color="#000000" bold={true}>
                  Opportunity Status*
                </Body>
              </Spacer>
            </CustomRow>
            <CustomRow id="order-report-btn-row">
              <Col className="pl-0" colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                <CustomCheckBoxRow>
                  <Col colSizes={{ mobile: 4, mobileLarge: 4, tablet: 4, tabletLarge: 4, desktop: 4, desktopLarge: 4, desktopXLarge: 4 }}>
                    <StyledSLCheckBox type="checkbox" selected={complete} onChange={completeHandler}>
                      Completed
                    </StyledSLCheckBox>
                  </Col>
                  <Col colSizes={{ mobile: 4, mobileLarge: 4, tablet: 4, tabletLarge: 4, desktop: 4, desktopLarge: 4, desktopXLarge: 4 }}>
                    <StyledSLCheckBox type="checkbox" selected={inProgress} onChange={inprogressHandler}>
                      In progress
                    </StyledSLCheckBox>
                  </Col>
                  <Col colSizes={{ mobile: 4, mobileLarge: 4, tablet: 4, tabletLarge: 4, desktop: 4, desktopLarge: 4, desktopXLarge: 4 }}>
                    <StyledSLCheckBox type="checkbox" selected={failed} onChange={failedHandler}>
                      Failed
                    </StyledSLCheckBox>
                  </Col>
                </CustomCheckBoxRow>
              </Col>
              <Col className="pl-0" colSizes={{ mobile: 2, tablet: 6, desktop: 3 }}>
                <Button css={StyledButton} size="large" onClick={getOpportunityHistory}>
                  Search
                </Button>
              </Col>

              <Col className="pl-0" colSizes={{ mobile: 2, tablet: 6, desktop: 3 }}>
                <Button css={StyledButton} secondary={true} size="large" onClick={resetHandler}>
                  Reset
                </Button>
              </Col>
            </CustomRow>
          </SLGridLayout>
        </Box>
        <br></br>
        <GridStyle>
          <div className="datatable-style">
            <GridComponent
              ref={tableRef}
              isSelectionEnabled={false}
              stateStorage="custom"
              customSaveState={onCustomSaveState}
              customRestoreState={onCustomRestoreState}
              rowClassName={rowClass}
              isExportEnabled={true}
              noDataMessage="Opportunity Report is Empty"
              rows={opportunityHistoryArr}
              hyperLinkClicked={handleHyperLinkClicked}
              renderDataWithImage={renderImage}
              columns={REPORTS_OPPORTUNITY_HISTORY_COL}
              title="Opportunity-History Data"
              enableColumnSelection
              reorderableColumns
              showResetButton
              rowExpansionTemplate={rowExpansionTemplate}
              rowExpansionPosition="beginning"
              scrollHeight="900px"
            />
          </div>
        </GridStyle>
      </LocationStyle>
    </>
  );
};
export default Reports;
